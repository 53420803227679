import React, { useEffect, useState } from 'react';
import ImageAndDescriptionFlyoutTaxonomyRenderer from '../components/flyout/FlyoutRendererWithImageAndDescription';
import ImageFlyoutTaxonomyRenderer from '../components/flyout/FlyoutRendererWithImage';
import TextOnlyFlyoutTaxonomyRenderer from '../components/flyout/FlyoutRendererTextOnly';
import CustomerWorkFlyoutTaxonomyRenderer from '../components/flyout/CustomerWorkRenderer';
import { connectTaxonomyRenderer } from './connectTaxonomyRenderer';
import {
  CategoriesResultTaxonomy,
  CustomerWorkResultTaxonomy,
  DesignResultTaxonomy,
  HelpResultTaxonomy,
  ProductResultTaxonomy
} from '../taxonomy/Taxonomies';

const ProductFlyoutTaxonomyRenderer = connectTaxonomyRenderer(
  ImageFlyoutTaxonomyRenderer(ProductResultTaxonomy.localizedHeader),
  ProductResultTaxonomy.id
);
const CategoriesFlyoutTaxonomyRenderer = connectTaxonomyRenderer(
  ImageFlyoutTaxonomyRenderer(CategoriesResultTaxonomy.localizedHeader),
  CategoriesResultTaxonomy.id
);

const DesignFlyoutTaxonomyRenderer = connectTaxonomyRenderer(
  ImageAndDescriptionFlyoutTaxonomyRenderer(DesignResultTaxonomy.localizedHeader),
  DesignResultTaxonomy.id
);
const HelpFlyoutTaxonomyRenderer = connectTaxonomyRenderer(
  TextOnlyFlyoutTaxonomyRenderer(HelpResultTaxonomy.localizedHeader),
  HelpResultTaxonomy.id
);

const CustomerFlyoutTaxonomyRenderer = connectTaxonomyRenderer(
  CustomerWorkFlyoutTaxonomyRenderer(CustomerWorkResultTaxonomy.localizedHeader),
  CustomerWorkResultTaxonomy.id
);

const TaxonomyRenderers: React.FC = () => {
  return (
    <>
      <CustomerFlyoutTaxonomyRenderer />
      <CategoriesFlyoutTaxonomyRenderer />
      <ProductFlyoutTaxonomyRenderer />
      <DesignFlyoutTaxonomyRenderer />
      <HelpFlyoutTaxonomyRenderer />
    </>
  );
};

export default TaxonomyRenderers;
