import { getIndexName } from '../../util/algolia';
import {
  DesignResultTaxonomy,
  HelpResultTaxonomy,
  ProductResultTaxonomy,
  ProductResultBertTaxonomy,
  CategoriesResultTaxonomy
} from '../taxonomy/Taxonomies';
import { AlgoliaIndexResultPlugin, getSearchParametersBuilder } from './AlgoliaPlugin';
import { ProjectsResultsPlugin } from './ProjectsPlugin';
import { OrderHistoryResultsPlugin } from './OrderHistoryPlugin';
import { ResultPlugin } from '../../types/Results';

const getBERTSearchParametersBuilder = (locale) => (userAbTests) => {
  let userSearchParameters = getSearchParametersBuilder(locale)(userAbTests);
  userSearchParameters.restrictSearchableAttributes = ['searchFields.queries'];
  return userSearchParameters;
};

export const ProductResultsPlugin = (
  locale: string,
  hitsPerPage: number,
  taxonomyId = ProductResultTaxonomy.id
): ResultPlugin => {
  const indexName = getIndexName('vistaprint', 'prod', locale, 'products');
  return AlgoliaIndexResultPlugin(
    indexName,
    indexName,
    taxonomyId,
    getSearchParametersBuilder(locale),
    hitsPerPage,
    'products'
  );
};

export const ProductResultsWithBERTPlugin = (
  locale,
  hitsPerPage: number,
  taxonomyId = ProductResultBertTaxonomy.id
) => {
  const indexName = getIndexName('vistaprint', 'prod', locale, 'products');
  return AlgoliaIndexResultPlugin(
    `${indexName}_BERT`,
    indexName,
    taxonomyId,
    getBERTSearchParametersBuilder(locale),
    hitsPerPage,
    'products'
  );
};
export const CategoriesResultsPlugin = (
  locale: string,
  hitsPerPage: number,
  taxonomyId = CategoriesResultTaxonomy.id
): ResultPlugin => {
  const indexName = getIndexName('vistaprint', 'prod', locale, 'categories');
  return AlgoliaIndexResultPlugin(
    indexName,
    indexName,
    taxonomyId,
    getSearchParametersBuilder(locale),
    hitsPerPage,
    'categories'
  );
};

export const GalleryResultsPlugin = (
  locale: string,
  hitsPerPage: number,
  taxonomyId = DesignResultTaxonomy.id
): ResultPlugin => {
  const indexName = getIndexName('vistaprint', 'prod', locale, 'gallery');
  return AlgoliaIndexResultPlugin(
    indexName,
    indexName,
    taxonomyId,
    getSearchParametersBuilder(locale),
    hitsPerPage,
    'gallery'
  );
};

export const HelpCenterResultsPlugin = (
  locale: string,
  hitsPerPage: number,
  taxonomyId = HelpResultTaxonomy.id
): ResultPlugin => {
  const indexName = getIndexName('vistaprint', 'prod', locale, 'helpcenter');
  return AlgoliaIndexResultPlugin(
    indexName,
    indexName,
    taxonomyId,
    getSearchParametersBuilder(locale),
    hitsPerPage,
    'helpcenter'
  );
};

export const MyAccountResultsPlugin = (
  locale: string,
  hitsPerPage: number,
  taxonomyId = HelpResultTaxonomy.id
): ResultPlugin => {
  const indexName = getIndexName('vistaprint', 'prod', locale, 'myaccount');
  return AlgoliaIndexResultPlugin(
    indexName,
    indexName,
    taxonomyId,
    getSearchParametersBuilder(locale),
    hitsPerPage,
    'myaccount'
  );
};

export const StaticPagesResultsPlugin = (
  locale: string,
  hitsPerPage: number,
  taxonomyId = HelpResultTaxonomy.id
): ResultPlugin => {
  const indexName = getIndexName('vistaprint', 'prod', locale, 'staticpages');
  return AlgoliaIndexResultPlugin(
    indexName,
    indexName,
    taxonomyId,
    getSearchParametersBuilder(locale),
    hitsPerPage,
    'staticpages'
  );
};

export const AllDefaultResultPlugins = (locale: string, hitsPerPage = 24): ResultPlugin[] => {
  return [
    ProductResultsPlugin(locale, hitsPerPage),
    ProductResultsWithBERTPlugin(locale, hitsPerPage),
    CategoriesResultsPlugin(locale, hitsPerPage),
    GalleryResultsPlugin(locale, hitsPerPage),
    MyAccountResultsPlugin(locale, hitsPerPage),
    HelpCenterResultsPlugin(locale, hitsPerPage),
    StaticPagesResultsPlugin(locale, hitsPerPage),
    ProjectsResultsPlugin(locale),
    OrderHistoryResultsPlugin(locale)
  ];
};
