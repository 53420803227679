import React, { useEffect } from 'react';
import { useSearchContext } from '../../searchContext/SearchContextProvider';
import { TaxonomyRenderer } from '../../types/TaxonomyRenderer';

export const connectTaxonomyRenderer = (component: TaxonomyRenderer, taxonomyId: string) => {
  return () => {
    const { registerTaxonomyRenderer } = useSearchContext();
    useEffect(() => registerTaxonomyRenderer(component, taxonomyId), []);
    return <></>;
  };
};
