import React from 'react';
export * from '.';
import { SearchBar as SearchBarImpl } from '.';
import { useStyles, useSwanStyleKeys } from '@vp/ubik-context';

import { stylesheet as searchInputStyles } from './base/components/SearchInput.scss';
import { stylesheet as searchFlyoutStyles } from './base/components/flyout/SearchFlyout.scss';

// these styles could be tied to the actual components, but for now they are here for demonstration purposes
// this seems to be the entrypoint for bookends code, flyout probably wouldn't have to be here during SSR,
// but ubik-context hasn't implemented client-side injection of styles yet.

// override default export of `SearchBar` with a HOC version that
// wraps the original `SearchBar` component with style registration
export const SearchBar: typeof SearchBarImpl = (props) => {
  useStyles(searchInputStyles, searchFlyoutStyles);
  useSwanStyleKeys(['core', 'button', 'icon', 'grid']);
  return <SearchBarImpl {...props} />;
};
