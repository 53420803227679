import React from 'react';
import { FlyoutResultProps } from './FlyoutResultHelpers';
import getRendererWithHeadingForResultType from './RendererWithLocalizedHeadingFactory';
import FlyoutResult from './FlyoutResult';
import ResultText from './ResultText';
import { Typography, VisuallyHidden } from '@vp/swan';

const Result = ({ result }: FlyoutResultProps) => {
  const label = result?._highlightResult?.title?.value;
  const ariaTitle = `${result?.title}`;

  return (
    <FlyoutResult
      resultId={result.objectID}
      result={result}
      landingUrl={result.landingUrl}
      style={{ fontSize: '16px' }}>
      <Typography className="search-flyout-item-name" textAlign="left" pl={3} pr={2} ml={3}>
        <span aria-hidden="true">
          <ResultText resultText={label} />
        </span>
        <VisuallyHidden>{ariaTitle}</VisuallyHidden>
        <span>{/* this is needed to stop Voiceover (mac) reading the wrong number of elements in the list*/}</span>
      </Typography>
    </FlyoutResult>
  );
};

export default getRendererWithHeadingForResultType(Result);
