import { getIndexName } from '../../util/algolia';
import {
  DesignResultTaxonomy,
  HelpResultTaxonomy,
  ProductResultTaxonomy,
  ProductResultBertTaxonomy,
  DiscoveryPagesTaxonomy,
  CategoriesResultTaxonomy
} from '../taxonomy/Taxonomies';
import { AlgoliaIndexResultPlugin, getSearchParametersBuilder } from './AlgoliaPlugin';
import { ResultPlugin } from '../../types/Results';
import { ProjectsResultsPlugin } from './ProjectsPlugin';
import { OrderHistoryResultsPlugin } from './OrderHistoryPlugin';
import { FlyoutDiscoveryProductResultsPlugin } from './FlyoutDiscoveryResultPlugins';
import { Experiment } from 'src/types/Experiment';

const getFlyoutSearchParametersBuilder = (locale) => (userAbTests) => {
  const userSearchParameters = getSearchParametersBuilder(locale)(userAbTests);
  userSearchParameters.attributesToRetrieve = [
    'objectID',
    'title',
    'description',
    'showInAbTests',
    'hideInAbTests',
    'landingUrl',
    'imageUrl',
    'inStock'
  ];
  userSearchParameters.attributesToHighlight = ['title', 'description'];
  return userSearchParameters;
};

const getFlyoutBERTSearchParametersBuilder = (locale) => (userAbTests) => {
  const userSearchParameters = getFlyoutSearchParametersBuilder(locale)(userAbTests);
  userSearchParameters.restrictSearchableAttributes = ['searchFields.queries'];
  return userSearchParameters;
};

export const FlyoutProductResultsPlugin = (
  locale: string,
  hitsPerPage: number,
  taxonomyId = ProductResultTaxonomy.id
): ResultPlugin => {
  const indexName = getIndexName('vistaprint', 'prod', locale, 'products');
  return AlgoliaIndexResultPlugin(
    indexName,
    indexName,
    taxonomyId,
    getFlyoutSearchParametersBuilder(locale),
    hitsPerPage,
    'products',
    true
  );
};

export const FlyoutProductResultsWithBERTPlugin = (
  locale,
  hitsPerPage: number,
  taxonomyId = ProductResultBertTaxonomy.id
) => {
  const indexName = getIndexName('vistaprint', 'prod', locale, 'products');
  return AlgoliaIndexResultPlugin(
    `${indexName}_BERT`,
    indexName,
    taxonomyId,
    getFlyoutBERTSearchParametersBuilder(locale),
    hitsPerPage,
    'products',
    true
  );
};
export const FlyoutCategoriesResultsPlugin = (
  locale: string,
  hitsPerPage: number,
  taxonomyId = CategoriesResultTaxonomy.id
): ResultPlugin => {
  const indexName = getIndexName('vistaprint', 'prod', locale, 'categories');
  return AlgoliaIndexResultPlugin(
    indexName,
    indexName,
    taxonomyId,
    getFlyoutSearchParametersBuilder(locale),
    hitsPerPage,
    'categories',
    true
  );
};

export const FlyoutGalleryResultsPlugin = (
  locale: string,
  hitsPerPage: number,
  taxonomyId = DesignResultTaxonomy.id
): ResultPlugin => {
  const indexName = getIndexName('vistaprint', 'prod', locale, 'gallery');
  return AlgoliaIndexResultPlugin(
    indexName,
    indexName,
    taxonomyId,
    getFlyoutSearchParametersBuilder(locale),
    hitsPerPage,
    'gallery',
    true
  );
};

export const FlyoutHelpCenterResultsPlugin = (
  locale: string,
  hitsPerPage: number,
  taxonomyId = HelpResultTaxonomy.id
): ResultPlugin => {
  const indexName = getIndexName('vistaprint', 'prod', locale, 'helpcenter');
  return AlgoliaIndexResultPlugin(
    indexName,
    indexName,
    taxonomyId,
    getFlyoutSearchParametersBuilder(locale),
    hitsPerPage,
    'helpcenter',
    true
  );
};

export const FlyoutMyAccountResultsPlugin = (
  locale: string,
  hitsPerPage: number,
  taxonomyId = HelpResultTaxonomy.id
): ResultPlugin => {
  const indexName = getIndexName('vistaprint', 'prod', locale, 'myaccount');
  return AlgoliaIndexResultPlugin(
    indexName,
    indexName,
    taxonomyId,
    getFlyoutSearchParametersBuilder(locale),
    hitsPerPage,
    'myaccount',
    true
  );
};

export const FlyoutStaticPagesResultsPlugin = (
  locale: string,
  hitsPerPage: number,
  taxonomyId = HelpResultTaxonomy.id
): ResultPlugin => {
  const indexName = getIndexName('vistaprint', 'prod', locale, 'staticpages');
  return AlgoliaIndexResultPlugin(
    indexName,
    indexName,
    taxonomyId,
    getFlyoutSearchParametersBuilder(locale),
    hitsPerPage,
    'staticpages',
    true
  );
};
export const FlyoutDiscoveryPagesResultsPlugin = (
  locale: string,
  hitsPerPage: number,
  taxonomyId = DiscoveryPagesTaxonomy.id
): ResultPlugin => {
  const indexName = getIndexName('vistaprint', 'prod', locale, 'categories');
  const getFlyoutDiscoveryPagesSearchParametersBuilder = (locale) => (userAbTests) => {
    let userSearchParameters = getFlyoutSearchParametersBuilder(locale)(userAbTests);
    userSearchParameters.filters = 'isDiscovery:true';
    userSearchParameters.getRankingInfo = true;
    return userSearchParameters;
  };

  return AlgoliaIndexResultPlugin(
    `flyout_discovery_pages_${locale}`,
    indexName,
    taxonomyId,
    getFlyoutDiscoveryPagesSearchParametersBuilder(locale),
    hitsPerPage,
    'discovery'
  );
};

export const FlyoutAllDefaultResultPlugins = (locale: string, hitsPerPage = 8): ResultPlugin[] => {
  return [
    FlyoutProductResultsPlugin(locale, hitsPerPage),
    FlyoutProductResultsWithBERTPlugin(locale, hitsPerPage),
    FlyoutCategoriesResultsPlugin(locale, hitsPerPage),
    FlyoutGalleryResultsPlugin(locale, hitsPerPage),
    FlyoutMyAccountResultsPlugin(locale, hitsPerPage),
    FlyoutHelpCenterResultsPlugin(locale, hitsPerPage),
    FlyoutStaticPagesResultsPlugin(locale, hitsPerPage),
    ProjectsResultsPlugin(locale),
    OrderHistoryResultsPlugin(locale)
  ];
};

export const FlyoutDefaultResultPlugins = (
  locale: string,
  hitsPerPage = 8,
  queryCategorizationExperiment: Experiment | undefined
): ResultPlugin[] => {
  return [
    FlyoutDiscoveryProductResultsPlugin(locale, hitsPerPage, queryCategorizationExperiment),
    FlyoutCategoriesResultsPlugin(locale, hitsPerPage)
  ];
};
