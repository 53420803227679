import React, { useEffect, useState } from 'react';
import SearchResultSectionAnalyticsWrapper from '../../base/components/SearchResultSectionAnalyticsWrapper';
import { useSearchContext } from '../../searchContext/SearchContextProvider';

const ResultsRenderer: React.FC<{ renderer: React.FC<any>; taxonomyId: string; alternateAnalyticsName?: string }> = ({
  renderer,
  taxonomyId,
  alternateAnalyticsName
}) => {
  const { results, resultDisplayConfiguration, updateResultsPaginationNumber, totalCount } = useSearchContext();
  const RendererComponent = renderer;
  const maxResults = resultDisplayConfiguration.maxResultsPerTaxonomy[taxonomyId]
    ? resultDisplayConfiguration.maxResultsPerTaxonomy[taxonomyId]
    : resultDisplayConfiguration.maxResultsPerTaxonomy.default;
  const [currentPaginationNumber, setCurrentPaginationNumber] = useState(1);
  const [matchedResults, setMatchedResults] = useState<any[]>([]);
  const [resultPositionLookup, setResultPositionLookup] = useState({ 1: { start: 0, end: maxResults - 1 } });

  useEffect(() => {
    const taxonomyResults = results[taxonomyId];
    if (taxonomyResults) {
      if (resultPositionLookup[currentPaginationNumber] != undefined) {
        setMatchedResults(
          taxonomyResults.slice(
            resultPositionLookup[currentPaginationNumber].start,
            resultPositionLookup[currentPaginationNumber].end + 1
          )
        );
      } else {
        const end =
          totalCount[taxonomyId] / currentPaginationNumber >= maxResults
            ? taxonomyResults.length + maxResults
            : taxonomyResults.length + (totalCount[taxonomyId] % maxResults) - 1;
        setResultPositionLookup({
          ...resultPositionLookup,
          [currentPaginationNumber]: {
            start: taxonomyResults.length,
            end: end
          }
        });
        // Performs Algolia API request and appends next page results to existing results
        // so we can slice desired results later
        updateResultsPaginationNumber(taxonomyId, currentPaginationNumber - 1);
      }
    }
  }, [results, currentPaginationNumber]);

  if (matchedResults && matchedResults.length > 0) {
    return (
      <SearchResultSectionAnalyticsWrapper taxonomyId={taxonomyId} sectionName={alternateAnalyticsName || taxonomyId}>
        <RendererComponent results={matchedResults} updatePaginationNumber={setCurrentPaginationNumber} />
      </SearchResultSectionAnalyticsWrapper>
    );
  } else {
    return null;
  }
};

export default ResultsRenderer;
