import React from 'react';
import { useSearchContext } from '../../../searchContext/SearchContextProvider';
import { TaxonomyRenderer } from '../../../types/TaxonomyRenderer';
import ErrorBoundary from '../../../util/ErrorBoundary';
import { List, Typography } from '@vp/swan';

type ResultComponent = React.FC<{ result: any }>;

const ErrorBoundaryWrapper: React.FC<React.PropsWithChildren<{ shouldWrap: boolean; component: string }>> = ({
  shouldWrap,
  component,
  children
}) => {
  const { logger } = useSearchContext();
  if (shouldWrap) {
    return (
      <ErrorBoundary logger={logger} component={component}>
        {children}
      </ErrorBoundary>
    );
  }
  return <>{children}</>;
};

export default (
  Result: ResultComponent,
  Footer?: React.FC,
  useSectionErrorBoundary = true,
  useResultErrorBoundary = true
) => {
  return (taxonomyHeadingLocalizer): TaxonomyRenderer => {
    return ({ results, maxResults, taxonomyConfiguration, headerId, fillToMaxResults }) => {
      const { locale } = useSearchContext();
      let resultsToRender: any[] = [];
      if (fillToMaxResults && taxonomyConfiguration) {
        resultsToRender = fillAllResultSlots(taxonomyConfiguration, maxResults, results);
      } else if (taxonomyConfiguration) {
        Object.keys(taxonomyConfiguration).forEach((resultType) => {
          const limit = taxonomyConfiguration[resultType];
          let count = 0;
          results.forEach((result) => {
            if (
              result.resultType === resultType &&
              count < limit &&
              resultsToRender.length < (maxResults || Infinity)
            ) {
              resultsToRender.push(result);
              count++;
            }
          });
        });
      } else {
        resultsToRender = results.slice(0, maxResults || results.length);
      }

      return (
        <ErrorBoundaryWrapper shouldWrap={useSectionErrorBoundary} component={`${headerId}-section`}>
          <Typography id={headerId} fontSkin="body-small" marginTop={5} marginBottom={3}>
            {taxonomyHeadingLocalizer(locale)}
          </Typography>

          <List skin="minimal" p={0}>
            {resultsToRender.map((result) => {
              return React.createElement(
                ErrorBoundaryWrapper,
                {
                  shouldWrap: useResultErrorBoundary,
                  component: `${headerId}-${result?.objectID}-result`,
                  key: result?.objectID
                },
                React.createElement(Result, { result })
              );
            })}
            {!!Footer && <Footer />}
          </List>
        </ErrorBoundaryWrapper>
      );
    };
  };
};

function fillAllResultSlots(taxonomyConfiguration, maxResults, taxonomyResults) {
  const resultTypes = Object.keys(taxonomyConfiguration);

  const taxonomyResultsByType: Record<string, any> = resultTypes.reduce((acc, taxonomyType) => {
    acc[taxonomyType] = taxonomyResults.filter((result) => result.resultType === taxonomyType);
    return acc;
  }, {});

  const pool: any[] = [];

  const result = Object.keys(taxonomyResultsByType).flatMap((taxomonyKey) => {
    const taxonomyResults = taxonomyResultsByType[taxomonyKey];
    const resultTypeMax = taxonomyConfiguration[taxomonyKey];
    const resultTypeCount = taxonomyResults.length;

    if (resultTypeCount >= resultTypeMax) {
      pool.push(...taxonomyResults.slice(resultTypeMax));
      return taxonomyResults.slice(0, resultTypeMax);
    } else {
      const needed = resultTypeMax - resultTypeCount;
      const fromPool = pool.splice(0, needed);
      return taxonomyResults.concat(fromPool);
    }
  });

  const remainingSlots = maxResults - result.length;
  if (remainingSlots > 0) {
    result.push(...pool.slice(0, remainingSlots));
  }

  return result;
}
