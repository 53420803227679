export const getLocalStorageItem = <T>(key: string, logger?: any): T | null => {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item!) : null;
  } catch (error) {
    const errorMessage = `Error getting item from localStorage`;
    logger ? logger.warn(errorMessage) : console.warn(errorMessage);
    return null;
  }
};

export const setLocalStorageItem = <T>(key: string, value: T, logger?: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    const errorMessage = `Error setting item from localStorage`;
    logger ? logger.warn(errorMessage) : console.warn(errorMessage);
  }
};
