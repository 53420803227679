import { useEffect, useState } from 'react';
import { getLocalStorageItem, setLocalStorageItem } from './localStorage';
import { useSearchContext } from '../searchContext/SearchContextProvider';
import { SEARCH_BAR_FLYOUT_APPLICATION_NAME } from '../util/constants';

export const useRecentSearch = () => {
  const RECENT_SEARCHES_KEY = 'recentSearches';
  const MAX_RECENT_SEARCHES = 5;
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const { logger, applicationName } = useSearchContext();

  useEffect(() => {
    const storedSearches = getLocalStorageItem<string[]>(RECENT_SEARCHES_KEY, logger);
    if (storedSearches) {
      setRecentSearches(storedSearches);
    }
  }, []);

  const addRecentSearch = (searchTerm: string) => {
    if (applicationName !== SEARCH_BAR_FLYOUT_APPLICATION_NAME) {
      return;
    }
    const normalizedSearchTerm = searchTerm.trim().toLowerCase().replace(/\s+/g, ' ');
    setRecentSearches((prevSearches) => {
      const filteredSearches = prevSearches.filter((search) => search !== normalizedSearchTerm);
      const updatedSearches = [normalizedSearchTerm, ...filteredSearches].slice(0, MAX_RECENT_SEARCHES);
      setLocalStorageItem(RECENT_SEARCHES_KEY, updatedSearches, logger);
      return updatedSearches;
    });
  };
  return { recentSearches, addRecentSearch };
};
