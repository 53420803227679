interface DiscoveryData {
  name: string;
  platformUrl: string;
  defaultImageUrl: string;
  objectID: string;
  inStock: boolean;
  _highlightResult: {
    name: {
      value: string;
    };
  };
  availabilityState: string;
  f_aka_ss: string[];
  f_descriptors_ss: string[];
  f_useCases_ss: string[];
  f_alternativeSpellings_ss: string[];
  f_galleryCategories_ss: string[];
}
interface SearchData {
  objectID: string;
  title: string;
  imageUrl: string;
  landingUrl: string;
  inStock: boolean;
  _highlightResult: {
    title: {
      value: string;
    };
  };
  searchFields?: {
    publiclyAvailable: boolean;
    aka?: string[];
    descriptor?: string[];
    useCase?: string[];
    altSpelling?: string[];
    galleryCategories?: string[];
    tags?: string[];
    queries?: string[];
  };
}
export function transformFromDiscoveryToSearch(hits: DiscoveryData[]): SearchData[] {
  return hits.map((hit) => {
    return {
      title: hit.name,
      imageUrl: hit.defaultImageUrl,
      landingUrl: hit.platformUrl,
      inStock: hit.inStock,
      objectID: hit.objectID,
      _highlightResult: {
        title: hit._highlightResult.name
      },
      searchFields: {
        publiclyAvailable: hit.availabilityState === 'Public',
        aka: hit.f_aka_ss,
        descriptor: hit.f_descriptors_ss,
        useCase: hit.f_useCases_ss,
        altSpelling: hit.f_alternativeSpellings_ss,
        galleryCategories: hit.f_galleryCategories_ss,
        tags: [],
        queries: []
      }
    };
  });
}
