import { algoliaSearchClients } from '../../util/algolia';
import { ABTest } from '../../types/abTest';
import { AlgoliaResult, FacetFilters, FacetSet, ResultPlugin, SectionTrackingDetails } from '../../types/Results';
import { Logger } from '../../types/Logger';
import { transformFromDiscoveryToSearch } from './transformFromDiscoveryToSearch';

const querySet: { [application: string]: { [key: string]: any } } = {};
let requestCache: { [application: string]: { [query: string]: Promise<any> } } = {};

const trackSearchResults = (resultSet, resultType) => {
  if (window.tracking) {
    window.tracking.track('Search Index Result Counts', {
      query: resultSet.query,
      totalResultCount: resultSet.nbHits,
      index: resultSet.index,
      location: window.location.pathname,
      resultType
    });
  }
};

const search = async (query, pluginId, logger) => {
  let allRequests: Promise<any>[] = [];
  let allQueryKeys: String[] = [];

  Object.keys(querySet).map((application) => {
    const queryKeys = Object.keys(querySet[application]);
    if (!requestCache[application]) {
      requestCache[application] = {};
    }
    let request = requestCache[application][query];
    if (!request) {
      request = algoliaSearchClients[application]
        .multipleQueries(
          queryKeys.map((key) => {
            return {
              ...querySet[application][key],
              query
            };
          })
        )
        .catch((err) => {
          logger?.error('Error fetching results from algolia', {
            contextData: {
              source: 'omnisearch',
              error: err.message,
              query,
              algoliaApplication: application,
              requestConfig: querySet[application]
            }
          });
          return { results: [] };
        });
      requestCache[application][query] = request;
    }
    allRequests.push(request);
    allQueryKeys.push(...queryKeys);
  });
  const data = await Promise.all(allRequests);
  const mergedDataArray = data.reduce((acc, currentObj) => acc.concat(currentObj.results), []);

  return mergedDataArray[allQueryKeys.indexOf(pluginId)];
};

export const getSearchParametersBuilder = (locale) => (userAbTests: ABTest[]) => {
  const defaultSearchParameters = {
    clickAnalytics: true,
    getRankingInfo: true,
    filters: 'searchFields.publiclyAvailable:true',
    restrictSearchableAttributes: [
      'title',
      'description',
      'searchFields.tags',
      'attributeFacets.ancestors',
      'searchFields.aka',
      'searchFields.descriptor',
      'searchFields.useCase',
      'searchFields.altSpelling',
      'pricingProductId',
      'searchFields.galleryCategories'
    ] //quirks of how Algolia allows querying on specific fields means we default to limiting searched fields
  };
  let userSearchParameters = defaultSearchParameters as any;

  // Will only affect indexes that also have turned on ai-reranking
  userSearchParameters.enableReRanking = true;

  //add if checks for additional tests here
  return userSearchParameters;
};

const defaultGetSearchParameters = getSearchParametersBuilder(undefined);

export const AlgoliaIndexResultPlugin = (
  id: string,
  indexName: string,
  taxonomyId: string,
  getSearchParams: ((userAbTests) => any) | (() => any) = defaultGetSearchParameters,
  hitsPerPage: number = 20,
  resultType?: string,
  isFlyout: boolean = false, //Used for Neural Search Test to change the flyout index set
  application: string = 'prod'
): ResultPlugin => {
  const registerPlugin = (
    pluginId: string,
    appliedFacetFilters?: FacetFilters,
    _sortStrategy?: string,
    userAbTests?: ABTest[],
    paginationNumber: number = 0
  ) => {
    if (!querySet[application]) {
      querySet[application] = {};
    }

    (querySet[application][pluginId] = {
      indexName,
      params: {
        ...getSearchParams(userAbTests),
        facetFilters: Object.keys(appliedFacetFilters || {}).map((key) => {
          return Array.from(appliedFacetFilters![key]).map((value) => `${key}:${value}`);
        }),
        facets: ['*'],
        page: paginationNumber,
        hitsPerPage: hitsPerPage
      }
    }),
      (requestCache = {});
  };

  const resultsPromise = async (
    pluginId: string,
    query: string,
    filters?: FacetFilters,
    sort?: string,
    userAbTests?: ABTest[],
    logger?: Logger
  ): Promise<{
    results: AlgoliaResult[];
    availableFacets: FacetSet;
    totalCount?: number;
    sectionTrackingDetails?: SectionTrackingDetails;
  }> => {
    return search(query, pluginId, logger)
      .then((data) => {
        if (indexName.includes('Discovery')) {
          const transformedHits = transformFromDiscoveryToSearch(data.hits);
          data.hits = transformedHits;
        }
        trackSearchResults(data, resultType);
        const results = data.hits.map((hit: any, searchPosition) => {
          const hideInTests = userAbTests?.filter((test) => {
            return (
              hit.hideInAbTests?.includes(`${test.Experiment}-${test.Variation}`) ||
              hit.showInAbTests?.some(
                (testKey) => testKey.startsWith(test.Experiment) && testKey !== `${test.Experiment}-${test.Variation}`
              )
            );
          });
          const hiddenFromLackOfTests = hit.showInAbTests
            ? !hit.showInAbTests?.some(
                (testKey) => testKey === 'none' || userAbTests?.some((test) => testKey.startsWith(test.Experiment))
              )
            : false;
          const algoliaResult = {
            indexName,
            searchPosition: 1 + searchPosition, //algolia specifically uses 1-indexed positions
            queryId: data.queryID,
            shouldHide: hideInTests?.length || hiddenFromLackOfTests,
            hideInTests,
            rankingInfo: hit._rankingInfo
          };
          return {
            ...hit,
            id: hit.objectID,
            algoliaResult,
            resultType,
            resultSource: indexName
          };
        }) as AlgoliaResult[];
        return {
          results,
          availableFacets: data.facets as FacetSet,
          totalCount: data.nbHits,
          sectionTrackingDetails: {
            rules: data.appliedRules?.map((rule) => `${indexName}:${rule.objectID}`)
          }
        };
      })
      .catch((err) => {
        logger?.error('Error proccessing results from algolia', {
          contextData: {
            source: 'omnisearch',
            error: err.message,
            pluginId,
            query,
            filters,
            sort,
            userAbTests
          }
        });
        return { results: [], availableFacets: {}, totalCount: 0 };
      });
  };
  return {
    pluginId: id,
    taxonomyId,
    resultsPromise,
    registerPlugin
  };
};
