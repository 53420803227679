export const getQueryParam = (param) => {
  if (typeof window?.location?.search !== 'undefined') {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    return vars.find((variable) => {
      var [key, value] = variable.split('=');
      if (decodeURIComponent(key) == param) {
        return decodeURIComponent(value);
      }
    });
  }
};
