import React from 'react';
import { FlyoutResultProps } from './FlyoutResultHelpers';
import getRendererWithHeadingForResultType from './RendererWithLocalizedHeadingFactory';
import FlyoutResult from './FlyoutResult';
import ResultText from './ResultText';
import { Typography, VisuallyHidden, SquareImageContainer, FluidImage, tokens } from '@vp/swan';

const Result = ({ result }: FlyoutResultProps) => {
  const label = result?._highlightResult?.title?.value;
  const ariaTitle = `${result?.title}`;

  return (
    <FlyoutResult resultId={result.objectID} result={result} landingUrl={result.landingUrl}>
      <SquareImageContainer
        bgc="strong"
        style={{ width: tokens.SwanSemSpace9, flexShrink: 0, borderRadius: tokens.SwanSemBorderRadiusStandard }}>
        <FluidImage src={result.imageUrl} alt="" />
      </SquareImageContainer>
      <Typography className="search-flyout-item-name" textAlign="left" pl={3} pr={2} ml={3}>
        <span aria-hidden="true">
          <ResultText resultText={label} />
        </span>
        <VisuallyHidden>{ariaTitle}</VisuallyHidden>
      </Typography>
    </FlyoutResult>
  );
};

export default getRendererWithHeadingForResultType(Result);
