import React from 'react';
import { Typography } from '@vp/swan';
const EM_TAG_PLACEHOLDER = 'EM_TAG_PLACEHOLDER';
const END_EM_TAG_PLACEHOLDER = 'END_EM_TAG_PLACEHOLDER';

const ResultText = ({ resultText }: { resultText: string }) => {
  if (!resultText) return null;

  if (resultText.indexOf('ais-highlight-0000000000') !== -1) {
    resultText = resultText.replace('ais-highlight-0000000000', 'em');
  }

  if (resultText.indexOf('<em>') === -1) return <>{resultText}</>;

  const chunks: string[] = [];
  let beginTag = true;
  let nextTagIndex = 0;

  while (resultText.length > 0) {
    if (beginTag) {
      nextTagIndex = resultText.indexOf('<em>');
    } else {
      nextTagIndex = resultText.indexOf('</em>');
    }

    if (nextTagIndex === -1) {
      chunks.push(resultText);
      break;
    }

    if (beginTag) {
      chunks.push(resultText.substring(0, nextTagIndex));
      chunks.push(EM_TAG_PLACEHOLDER);
      resultText = resultText.slice(nextTagIndex + 4);
    } else {
      chunks.push(resultText.substring(0, nextTagIndex));
      chunks.push(END_EM_TAG_PLACEHOLDER);
      resultText = resultText.slice(nextTagIndex + 5);
    }
    beginTag = !beginTag;
  }

  beginTag = false;

  return (
    <>
      {chunks.map((chunk, index) => {
        if (chunk === EM_TAG_PLACEHOLDER) {
          beginTag = true;
          return;
        }
        if (chunk === END_EM_TAG_PLACEHOLDER) {
          beginTag = false;
          return;
        }
        if (beginTag) {
          return (
            <Typography as="mark" fontWeight="bold" backgroundColor="transparent" key={chunk + index} color="standard">
              {chunk}
            </Typography>
          );
        } else {
          return chunk;
        }
      })}
    </>
  );
};

export default ResultText;
