import {
  CategoriesResultTaxonomy,
  CustomerWorkResultTaxonomy,
  DesignResultTaxonomy,
  HelpResultTaxonomy,
  InspirationResultTaxonomy,
  ProductResultBertTaxonomy,
  ProductResultTaxonomy
} from '../../taxonomy/Taxonomies';
import { ResultDisplayConfiguration } from '../../../types/Results';
import { HELP_CENTER_APPLICATION_NAME } from '../../../util/constants';

export const helpCenterSearchBarDisplayConfiguration: ResultDisplayConfiguration = {
  maxResults: 15,
  maxResultsPerTaxonomy: { default: 3, [CustomerWorkResultTaxonomy.id]: 2 },
  taxonomyOrder: [
    CustomerWorkResultTaxonomy.id,
    HelpResultTaxonomy.id,
    CategoriesResultTaxonomy.id,
    ProductResultTaxonomy.id,
    DesignResultTaxonomy.id,
    InspirationResultTaxonomy.id
  ],
  resultTypeOrderingPerTaxonomy: {
    [HelpResultTaxonomy.id]: ['myaccount', 'helpcenter', 'staticpages'],
    [CategoriesResultTaxonomy.id]: ['categories'],
    [ProductResultTaxonomy.id]: ['products'],
    [CustomerWorkResultTaxonomy.id]: ['work_entity_service', 'order_history']
  },
  taxonomyConfiguration: {
    [CustomerWorkResultTaxonomy.id]: [
      { resultTypeId: 'work_entity_service', maxResultsByResultType: 1 },
      { resultTypeId: 'order_history', maxResultsByResultType: 1 }
    ]
  },
  taxonomyFallback: { [ProductResultTaxonomy.id]: ProductResultBertTaxonomy.id }
};

export const searchBarDisplayConfiguration: ResultDisplayConfiguration = {
  maxResults: 8,
  maxResultsPerTaxonomy: { [ProductResultTaxonomy.id]: 8, [CategoriesResultTaxonomy.id]: 4 },
  taxonomyOrder: [CategoriesResultTaxonomy.id, ProductResultTaxonomy.id],
  resultTypeOrderingPerTaxonomy: {
    [CategoriesResultTaxonomy.id]: ['categories'],
    [ProductResultTaxonomy.id]: ['products']
  },
  taxonomyFallback: { [ProductResultTaxonomy.id]: ProductResultBertTaxonomy.id },
  includeTaxonomies: [CategoriesResultTaxonomy.id, ProductResultTaxonomy.id],
  taxonomyConfiguration: {
    [CategoriesResultTaxonomy.id]: [{ resultTypeId: 'categories', maxResultsByResultType: 8 }],
    [ProductResultTaxonomy.id]: [{ resultTypeId: 'products', maxResultsByResultType: 8 }]
  },
  fillToMaxResults: true
};

export function displayConfigurationDecider(applicationName?: string) {
  if (applicationName === HELP_CENTER_APPLICATION_NAME) {
    return helpCenterSearchBarDisplayConfiguration;
  }

  return searchBarDisplayConfiguration;
}
