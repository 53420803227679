import React from 'react';
import { useSearchContext } from '../../searchContext/SearchContextProvider';

const NoResultsSection: React.FC<React.PropsWithChildren<{ taxonomies?: string[]; trackingSectionName?: string }>> = ({
  children,
  taxonomies,
  trackingSectionName
}) => {
  const { results, query, applicationName } = useSearchContext();
  taxonomies = taxonomies || Object.keys(results);
  const noResultsForTaxonomies =
    Object.keys(results).length > 0 &&
    taxonomies.every(
      (taxonomy) =>
        !results[taxonomy] ||
        !results[taxonomy].length ||
        results[taxonomy].every((result) => result.algoliaResult?.shouldHide)
    );
  React.useEffect(() => {
    let currentTimeout;
    if (noResultsForTaxonomies && window.tracking && trackingSectionName) {
      //debounce analytics events, fire only after 750 miliseconds
      currentTimeout = setTimeout(() => {
        window.tracking.track('Search Result Section', {
          query: query,
          sectionName: trackingSectionName,
          location: window.location.pathname,
          applicationName
        });
      }, 750);
    }
    return () => {
      if (currentTimeout) clearTimeout(currentTimeout);
    };
  }, [query]);

  if (noResultsForTaxonomies) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default NoResultsSection;
