import { ResultPlugin } from 'src/types/Results';
import { AlgoliaIndexResultPlugin } from './AlgoliaPlugin';
import { ProductResultTaxonomy } from '../taxonomy/Taxonomies';
import { Experiment } from '../../types/Experiment';
import { QUERY_CATEGORIZATION_EXPERIMENT_VARIATION } from '../../util/abTestConstants';

export const getFlyoutSearchParametersBuilderDiscovery = () => () => {
  const defaultSearchParameters = {
    clickAnalytics: true,
    attributesToRetrieve: ['platformUrl', 'name', 'defaultImageUrl', 'inStock'],
    attributesToHighlight: ['name'],
    ruleContexts: ['defaultRuleContext']
  };

  return defaultSearchParameters;
};

export const FlyoutDiscoveryProductResultsPlugin = (
  locale: string,
  hitsPerPage: number,
  queryCategorizationExperiment: Experiment | undefined,
  taxonomyId = ProductResultTaxonomy.id
): ResultPlugin => {
  let indexName = getIndexNameDiscovery(locale);
  if (
    queryCategorizationExperiment &&
    queryCategorizationExperiment.Variation === QUERY_CATEGORIZATION_EXPERIMENT_VARIATION
  ) {
    indexName = `${indexName}_queryCategorization`;
  }

  return AlgoliaIndexResultPlugin(
    indexName,
    indexName,
    taxonomyId,
    getFlyoutSearchParametersBuilderDiscovery(),
    hitsPerPage,
    'products',
    true,
    'prod'
  );
};

export const getIndexNameDiscovery = (culture: string) => {
  return `Vistaprint_prod_Discovery_${culture}`;
};
