import { Reducer } from 'react';
import { InternalSearchApplicationState } from './SearchContextProvider';
import { TaxonomyRenderer } from '../types/TaxonomyRenderer';
import { FacetSet, SectionTrackingDetails } from 'src/types/Results';
import { applyFacet, removeFacet } from './SearchContextFacetManipulations';

export const SET_CURRENT_QUERY = 'SET_CURRENT_QUERY';
export const ADD_RESULTS = 'ADD_RESULTS';
export const APPLY_FACET = 'APPLY_FACET';
export const REMOVE_FACET = 'REMOVE_FACET';
export const UPDATE_FACET_SELECTIONS = 'UPDATE_FACET_SELECTIONS';
export const APPLY_SORT = 'APPLY_SORT';
export const CLEAR_RESULTS = 'CLEAR_RESULTS';
export const RESET_RESULTS_AND_FACETS = 'RESET_RESULTS_AND_FACETS';
export const RESET_RESULTS = 'RESET_RESULTS';
export const SET_ALL_RESULTS_URL = 'SET_ALL_RESULTS_URL';
export const ADD_RENDERER = 'ADD_RENDERER';
export const GET_RESULTS = 'GET_RESULTS';

export interface ReducerAction {
  type: string;
}
export interface QueryAction extends ReducerAction {
  value: string;
}
export interface ResultAction extends ReducerAction {
  results: any[];
  availableFacets: FacetSet;
  taxonomyId: string;
  totalCount: number;
  sectionTrackingDetails: SectionTrackingDetails;
  shouldReportComplete: boolean;
  hasToResetResults?: boolean;
}
export interface PaginationAction extends ReducerAction {
  pluginId: string;
  resultsPaginationNumber: number;
}

export interface FacetSpecification {
  facetKey: string;
  facetValue: string | string[];
}
export interface SimpleFacetAction extends ReducerAction, FacetSpecification {
  taxonomyId: string | string[];
}

export interface CompoundFacetAction extends ReducerAction {
  taxonomyId: string | string[];
  facetsToApply: FacetSpecification | FacetSpecification[];
  facetsToRemove: FacetSpecification | FacetSpecification[];
}
export interface SortAction extends ReducerAction {
  taxonomyId: string | string[];
  sort: string;
}
export interface RendererAction extends ReducerAction {
  renderer: TaxonomyRenderer;
  taxonomyId: string;
}
export type SearchContextAction = QueryAction | ResultAction | ReducerAction | RendererAction | PaginationAction;

export const SearchContextReducer: Reducer<InternalSearchApplicationState, SearchContextAction> = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_QUERY:
      return {
        ...state,
        currentQuery: (action as QueryAction).value
      };
    case RESET_RESULTS_AND_FACETS:
      return {
        ...state,
        previousResults: { ...state.results },
        availableFacets: {},
        totalCount: {},
        hasToAppendResults: {},
        reportedResultPluginCount: 0
      };
    case RESET_RESULTS:
      const preservedFacets = Object.keys(state.appliedFacets).reduce((taxonomiesWithFacets, taxonomy) => {
        taxonomiesWithFacets[taxonomy] = Object.keys(state.appliedFacets[taxonomy]).reduce(
          (facetsToKeep, facetName) => {
            facetsToKeep[facetName] = state.availableFacets[taxonomy]?.[facetName];
            return facetsToKeep;
          },
          {}
        );
        return taxonomiesWithFacets;
      }, {});
      return {
        ...state,
        previousResults: { ...state.results },
        results: {},
        availableFacets: preservedFacets,
        totalCount: {},
        reportedResultPluginCount: 0
      };
    case CLEAR_RESULTS:
      return {
        ...state,
        results: {},
        previousResults: {},
        hasToAppendResults: {}
      };
    case ADD_RESULTS:
      const resultAction = action as ResultAction;
      const newResults = { ...state!.results };
      if (!state.hasToAppendResults[resultAction.taxonomyId]) {
        newResults[resultAction.taxonomyId] = [...resultAction.results];
      } else {
        newResults[resultAction.taxonomyId] = [
          ...(state?.results[resultAction.taxonomyId] ?? []),
          ...resultAction.results
        ];
      }

      const newTotalCount = { ...state!.totalCount };
      newTotalCount[resultAction.taxonomyId] = resultAction.totalCount;

      //Todo: maybe shared facet-merge function here and with the provider availableFacets function?
      //Goal - add facets and facet value result counts to the taxonomy's list of current facets and facet values, while preserving any data that has already been returned by other result plugins. The end result should be a combined record of all possible facets and values from the result plugins for a taxonomy, adding result counts to the facet values rather than duplicating entries.
      const newFacets = resultAction.availableFacets ? { ...state!.availableFacets } : state!.availableFacets;
      if (resultAction.availableFacets) {
        //It is possible this is the first/only plugin to return facets, so prepare the top level record for the taxonomy
        if (!newFacets[resultAction.taxonomyId]) {
          newFacets[resultAction.taxonomyId] = {};
        }
        Object.keys(resultAction.availableFacets).forEach((facet) => {
          //It is possible that this facet key (facetable attributes) is brand new, either because this is the first result plugin for the taxonomy or other result plugins did not have this facet, so we must prepare the object for this facet.
          if (!newFacets[resultAction.taxonomyId][facet]) {
            newFacets[resultAction.taxonomyId][facet] = {};
          }
          Object.keys(resultAction.availableFacets[facet]).forEach((facetValue) => {
            //It is possible this is a new value for the facet
            if (!newFacets[resultAction.taxonomyId][facet][facetValue]) {
              newFacets[resultAction.taxonomyId][facet][facetValue] = 0;
            }
            //In the case that this facet key and value already were returned from the another result plugin, we want to increase the number of results, not override.
            newFacets[resultAction.taxonomyId][facet][facetValue] += resultAction.availableFacets[facet][facetValue];
          });
        });
      }
      const newSectionTrackingDetails = resultAction.sectionTrackingDetails
        ? { ...state.sectionTrackingDetails }
        : state.sectionTrackingDetails;
      if (resultAction.sectionTrackingDetails) {
        if (!newSectionTrackingDetails[resultAction.taxonomyId]) {
          newSectionTrackingDetails[resultAction.taxonomyId] = {};
        }
        //Make this more generic if/when additional fields are added. Until that time no need to increase complexity and reduce readability
        if (newSectionTrackingDetails[resultAction.taxonomyId].rules && resultAction.sectionTrackingDetails) {
          newSectionTrackingDetails[resultAction.taxonomyId].rules = newSectionTrackingDetails[
            resultAction.taxonomyId
          ].rules!.concat(resultAction.sectionTrackingDetails.rules || []);
        } else if (resultAction.sectionTrackingDetails) {
          newSectionTrackingDetails[resultAction.taxonomyId].rules = resultAction.sectionTrackingDetails.rules;
        }
      }
      return {
        ...state,
        partialResults: newResults,
        results: newResults,
        totalCount: newTotalCount,
        availableFacets: newFacets,
        reportedResultPluginCount: resultAction.shouldReportComplete
          ? state.reportedResultPluginCount + 1
          : state.reportedResultPluginCount,
        sectionTrackingDetails: newSectionTrackingDetails,
        hasToAppendResults: {
          ...state.hasToAppendResults,
          [resultAction.taxonomyId]: true
        }
      };
    case APPLY_FACET: {
      const facetAction = action as SimpleFacetAction;
      const appliedFacets = applyFacet(state.appliedFacets, facetAction, facetAction.taxonomyId);
      return {
        ...state,
        appliedFacets
      };
    }
    case REMOVE_FACET: {
      const facetAction = action as SimpleFacetAction;
      const appliedFacets = removeFacet(state.appliedFacets, facetAction, facetAction.taxonomyId);
      return {
        ...state,
        appliedFacets
      };
    }
    case UPDATE_FACET_SELECTIONS: {
      const facetAction = action as CompoundFacetAction;
      let appliedFacets = { ...state.appliedFacets };
      if (Array.isArray(facetAction.facetsToApply)) {
        appliedFacets = facetAction.facetsToApply.reduce((newAppliedfacets, currentFacetToApply) => {
          return applyFacet(newAppliedfacets, currentFacetToApply, facetAction.taxonomyId);
        }, appliedFacets);
      } else {
        appliedFacets = applyFacet(state.appliedFacets, facetAction.facetsToApply, facetAction.taxonomyId);
      }
      if (Array.isArray(facetAction.facetsToRemove)) {
        appliedFacets = facetAction.facetsToRemove.reduce((newAppliedfacets, currentFacetToRemove) => {
          return removeFacet(newAppliedfacets, currentFacetToRemove, facetAction.taxonomyId);
        }, appliedFacets);
      } else {
        appliedFacets = removeFacet(appliedFacets, facetAction.facetsToRemove, facetAction.taxonomyId);
      }
      return {
        ...state,
        appliedFacets
      };
    }
    case APPLY_SORT:
      const sortStrategies = { ...state.sortStrategies };
      let taxonomies: string[] = [];
      if (typeof (action as SortAction).taxonomyId === 'string') {
        taxonomies.push((action as SortAction).taxonomyId as string);
      } else {
        taxonomies = (action as SortAction).taxonomyId as string[];
      }
      taxonomies.forEach((taxonomy) => {
        sortStrategies[taxonomy] = (action as SortAction).sort;
      });
      return {
        ...state,
        sortStrategies
      };
    case SET_ALL_RESULTS_URL:
      return {
        ...state,
        allResultsUrl: (action as QueryAction).value
      };
    case ADD_RENDERER:
      const newRenders = { ...state!.renderers };
      newRenders[(action as RendererAction).taxonomyId] = (action as RendererAction).renderer;
      return {
        ...state,
        renderers: newRenders
      };
    default:
      console.warn(`unhandled reducer action: ${action.type}`);
      return {
        ...state
      };
  }
};
