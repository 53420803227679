import Fuse from 'fuse.js';
import { CustomerProjectsResult, FacetFilters, FacetSet, ResultsPromise } from '../../types/Results';
import { ABTest } from '../../types/abTest';
import { Logger } from '../../types/Logger';

let orderCache: { [locale: string]: Promise<any[]> | undefined } = {};

const tenantMap: { [id: string]: string } = {
  'en-gb': 'VP-GB-PROD',
  'de-de': 'VP-DE-PROD',
  'fr-fr': 'VP-FR-PROD',
  'it-it': 'VP-IT-PROD',
  'sv-se': 'VP-SE-PROD',
  'nl-nl': 'VP-NL-PROD',
  'es-es': 'VP-ES-PROD',
  'fr-be': 'VP-BE-PROD',
  'nl-be': 'VP-BE-PROD',
  'de-ch': 'VP-CH-PROD',
  'fr-ch': 'VP-CH-PROD',
  'it-ch': 'VP-CH-PROD',
  'en-ie': 'VP-IE-PROD',
  'da-dk': 'VP-DK-PROD',
  'de-at': 'VP-AT-PROD',
  'nb-no': 'VP-NO-PROD',
  'fi-fi': 'VP-FI-PROD',
  'pt-pt': 'VP-PT-PROD',
  'en-us': 'VP-US-PROD',
  'es-us': 'VP-US-PROD',
  'en-ca': 'VP-CA-PROD',
  'fr-ca': 'VP-CA-PROD',
  'en-au': 'VP-AU-PROD',
  'en-nz': 'VP-NZ-PROD',
  'en-sg': 'VP-SG-PROD',
  'ja-jp': 'VP-JP-PROD',
  'en-in': 'VP-IN-PROD'
};

const callPaginatedOrderAPI = (auth, locale, offset = 0, previousResponse: any = {}) => {
  return fetch(
    `https://ordermanagement.orders.vpsvc.com/api/v2/ui/shopper/${auth.getCanonicalId()}/orders/summaries?limit=100&tenantId=${tenantMap[locale.toLowerCase()]}&offset=${offset}`,
    {
      method: 'GET',
      headers: { authorization: auth.getAuthorizationHeader() }
    }
  ).then((response) => {
    if (response && response.ok) {
      return response.json().then((newResponse) => {
        const orders = previousResponse.orders
          ? [...previousResponse.orders, ...newResponse.orders]
          : newResponse.orders;
        const response = { ...previousResponse, ...newResponse, orders };
        if (orders.length < newResponse.total) {
          offset++;
          return callPaginatedOrderAPI(auth, locale, offset, response);
        }

        return response;
      });
    }
  });
};

const fillOrderCache = (auth: any, locale: string, logger?: Logger) => {
  orderCache[locale] = callPaginatedOrderAPI(auth, locale)
    .then((results) => {
      if (results) {
        return results.orders.reduce((total, order) => {
          try {
            if (order.lineItems.length) {
              total.push({
                orderId: order.orderNumber,
                state: order.state,
                items: order.lineItems.map((lineItem) => {
                  return {
                    id: lineItem.id,
                    productName: lineItem.productName,
                    matchingAttributes: lineItem.displayProductAttributes?.map(
                      (attribute) => attribute.valueDisplayName
                    ),
                    purcsData: lineItem.purcsData,
                    livePreviewUrl: lineItem.livePreviewUrl
                  };
                })
              });
              return total;
            }
          } catch (e) {
            logger?.warn('Error processing order from web response', {
              contextData: { source: 'omnisearch', error: e.message }
            });
            return total;
          }
        }, []);
      }
      return [];
    })
    .catch((e) => {
      logger?.error('Error fetching orders', { contextData: { source: 'omnisearch', error: e.message } });
      return [];
    });
};

const searchOrders = (orders: any[], query: string, logger?: Logger) => {
  try {
    const fuse = new Fuse(orders, {
      keys: ['items.matchingAttributes.value', 'items.productName'],
      includeScore: true,
      minMatchCharLength: 2,
      ignoreLocation: true,
      threshold: 0.25,
      shouldSort: true
    });
    const searchResults = fuse.search(query);
    searchResults
      .filter((result) => result && result.score !== undefined && result.score < 0.25) //lower is better
      .forEach((result) => {
        const orderFuse = new Fuse(result.item.items, {
          keys: ['matchingAttributes.value', 'productName'],
          includeScore: true,
          minMatchCharLength: 2,
          ignoreLocation: true,
          threshold: 0.25,
          shouldSort: true
        });
        const targetLineItem = orderFuse.search(query)[0];
        result.item.lineItemMatchIndex = targetLineItem?.refIndex;
      });
    return searchResults;
  } catch (err) {
    logger?.error('Error searching orders', { contextData: { source: 'omnisearch', error: err.message } });
    return [];
  }
};

export const OrderHistoryResultsPlugin = (locale: string) => {
  const findCustomerOrders: ResultsPromise = async (
    _id: string,
    query: string,
    _appliedFacetFilters?: FacetFilters,
    _sortStrategy?: string,
    _userAbTests?: ABTest[],
    logger?: Logger
  ): Promise<{ results: CustomerProjectsResult[]; availableFacets: FacetSet; totalCount: number }> => {
    const orders = (await orderCache[locale]) as any[];
    if (orders && orders.length) {
      const searchResults = searchOrders(orders, query, logger);
      const results = searchResults
        .map((order) => {
          try {
            const result = order.item.items[order.item.lineItemMatchIndex || 0];
            return {
              landingUrl: `/od?orderId=${order.item.orderId}`,
              workRevisionId: result.purcsData?.workRevisionId,
              id: result.id,
              livePreviewUrl: result.livePreviewUrl,
              name: result.productName.trim(),
              resultSource: 'Order History',
              resultType: 'order_history',
              orderId: order.item.orderId
            };
          } catch (e) {
            logger?.warn('Error processing order into result', {
              contextData: { source: 'omnisearch', error: e.message }
            });
            return undefined;
          }
        })
        .filter((order) => order) as CustomerProjectsResult[];
      return { results, availableFacets: {}, totalCount: results.length };
    }
    return { results: [], availableFacets: {}, totalCount: 0 };
  };
  return {
    pluginId: 'orderHistory',
    registerPlugin: (
      _pluginId: string,
      _appliedFacetFilters?: FacetFilters,
      _sortStrategy?: string,
      _userAbTests?: ABTest[],
      _paginationNumber?: number,
      auth?: any,
      logger?: Logger
    ) => {
      if (auth && auth.getAuthorizationHeader && !orderCache[locale]) {
        fillOrderCache(auth, locale, logger);
      }
    },
    resultsPromise: findCustomerOrders,
    taxonomyId: 'CUSTOMER_WORK_RESULTS'
  };
};
