import React, { useEffect } from 'react';
import { v4 as guid } from 'uuid';
import { SearchContextProvider } from './searchContext/SearchContextProvider';
import { ANALYTICS_WRAPPER_CLASS } from './util/analyticsConstants';
import { useSearchContext } from './searchContext/SearchContextProvider';
import { ResultDisplayConfiguration, ResultPlugin } from './types/Results';
import { getUrlPrefix } from './util/secondaryLanguageUrlPrefix';
import { EnabledTestFeatures } from './types/abTest';
import { Logger } from './types/Logger';

export interface SearchApplicationProps {
  minCharLength?: number;
  defaultToAwaitingResults?: boolean;
  locale: string;
  resultsPageUrl?: string;
  getResultsPageUrl?: (query: string) => string;
  queryString?: string;
  applicationName?: string;
  trackExternalClicks?: boolean;
  resultPlugins?: ResultPlugin[];
  instanceId?: string;
  resultDisplayConfiguration?: ResultDisplayConfiguration;
  enabledAbTests?: EnabledTestFeatures;
  auth?: any; //make required in next major version
  waitOnTestLoad?: boolean;
  logger?: Logger;
}

const ExternalClickTracker = ({ application }) => {
  const { query } = useSearchContext();
  useEffect(() => {
    const externalClickTracker = (evt) => {
      const anchor = evt.target.closest('a');
      const searchApplication = evt.target.closest(`.${ANALYTICS_WRAPPER_CLASS}`)?.dataset.searchApplicationName;
      const notSearchLink = searchApplication !== application || !evt.target.closest(`.${ANALYTICS_WRAPPER_CLASS}`); //Unlikely to hit this case, but there might be links in the app not considered results
      if (!!anchor && notSearchLink) {
        window.tracking.track('Search Application Non-Result Clicked', {
          application,
          destination: anchor.attributes.href?.nodeValue,
          query
        });
      }
    };
    document.addEventListener('click', externalClickTracker);
    return () => {
      document.removeEventListener('click', externalClickTracker);
    };
  }, [query]);
  return <></>;
};

const SearchApplication: React.FC<React.PropsWithChildren<SearchApplicationProps>> = ({
  children,
  locale,
  minCharLength = 3,
  defaultToAwaitingResults = false,
  resultsPageUrl = `${getUrlPrefix(locale)}/search`,
  getResultsPageUrl,
  queryString,
  applicationName = 'SearchResults',
  trackExternalClicks = false,
  resultPlugins = [],
  instanceId = guid(),
  resultDisplayConfiguration,
  enabledAbTests,
  auth,
  waitOnTestLoad,
  logger
}) => {
  return (
    <SearchContextProvider
      minCharLength={minCharLength}
      defaultToAwaitingResults={defaultToAwaitingResults}
      locale={locale}
      resultsPageUrl={resultsPageUrl}
      getResultsPageUrl={getResultsPageUrl}
      queryString={queryString}
      resultPlugins={resultPlugins}
      instanceId={instanceId}
      resultDisplayConfiguration={resultDisplayConfiguration}
      applicationName={applicationName}
      enabledAbTests={enabledAbTests}
      auth={auth}
      waitOnTestLoad={waitOnTestLoad}
      logger={logger}>
      {trackExternalClicks && <ExternalClickTracker application={applicationName} />}
      <div className={`search-application ${ANALYTICS_WRAPPER_CLASS}`} data-search-application-name={applicationName}>
        {children}
      </div>
    </SearchContextProvider>
  );
};

export default SearchApplication;
