import algoliasearch from 'algoliasearch';

export const ALGOLIA_APP_ID = 'A31FV0KTHH';
export const ALGOLIA_CLIENT = '2616b819315a92abf68c6db6484b5aa9';

// Sandbox for testing
export const SANDBOX_APP_ID = 'testingUT60ZKBEX6';
export const SANDBOX_CLIENT = 'b08e87b98344955186a90ce67a36e58a';

export const DEFAULT_INDEX_SUFFIX = 'products';

export const algoliaSearchClients = {
  sandbox: algoliasearch(SANDBOX_APP_ID, SANDBOX_CLIENT),
  prod: algoliasearch(ALGOLIA_APP_ID, ALGOLIA_CLIENT)
};

export const getIndexName = (tenant: string, environment: 'dev' | 'prod', locale: string, suffix: string) => {
  return `${tenant}_${environment}_${locale}_${suffix}`.toLowerCase();
};
