import { FacetFilters } from 'src/types/Results';
import { SimpleFacetAction, FacetSpecification } from './SearchContextReducer';

export const applyFacet = (
  existingAppliedFacets: { [x: string]: FacetFilters },
  facetSpec: FacetSpecification | SimpleFacetAction,
  taxonomyId: string | string[]
): { [x: string]: FacetFilters } => {
  //Goal: add one or more facet to the applied facet lists for one or more provided taxonomy. The process to do that is to get to the list of applied facets for each taxonomy provided, then add either the single facet or list of facets passed.
  const newAppliedFacets = { ...existingAppliedFacets };
  let taxonomiesList: string[] = [];
  if (typeof taxonomyId === 'string') {
    taxonomiesList.push(taxonomyId);
  } else {
    taxonomiesList = taxonomyId;
  }
  taxonomiesList.forEach((taxonomyId) => {
    if (!newAppliedFacets[taxonomyId]) {
      newAppliedFacets[taxonomyId] = {};
    }
    const taxonomyFacets = newAppliedFacets[taxonomyId];
    if (!taxonomyFacets[facetSpec.facetKey]) {
      taxonomyFacets[facetSpec.facetKey] = new Set<string>();
    }
    if (typeof facetSpec.facetValue == 'string') {
      taxonomyFacets[facetSpec.facetKey].add(facetSpec.facetValue);
    } else {
      facetSpec.facetValue.forEach((value) => {
        taxonomyFacets[facetSpec.facetKey].add(value);
      });
    }
  });
  return newAppliedFacets;
};

export const removeFacet = (
  existingAppliedFacets: { [x: string]: FacetFilters },
  facetSpec: FacetSpecification | SimpleFacetAction,
  taxonomyId: string | string[]
): { [x: string]: FacetFilters } => {
  const newAppliedFacets = { ...existingAppliedFacets };
  let taxonomiesList: string[] = [];
  if (typeof taxonomyId === 'string') {
    taxonomiesList.push(taxonomyId);
  } else {
    taxonomiesList = taxonomyId;
  }
  taxonomiesList.forEach((taxonomyId) => {
    if (newAppliedFacets[taxonomyId] && newAppliedFacets[taxonomyId][facetSpec.facetKey]) {
      if (typeof facetSpec.facetValue == 'string')
        (newAppliedFacets[taxonomyId][facetSpec.facetKey] as Set<string>).delete(facetSpec.facetValue);
      else
        facetSpec.facetValue.forEach((value) =>
          (newAppliedFacets[taxonomyId][facetSpec.facetKey] as Set<string>).delete(value)
        );
    }
  });
  return newAppliedFacets;
};
