const localePrefixMap = {
  'fr-ca': '/fr',
  'es-us': '/es',
  'fr-ch': '/fr',
  'fr-be': '/fr',
  'it-ch': '/it',
  'en-jp': '/en'
};

export const getUrlPrefix = (locale: string) => {
  return localePrefixMap[locale.toLocaleLowerCase()] || '';
};
