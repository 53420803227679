import React, { useState } from 'react';
import { v4 as guid } from 'uuid';
import { useSearchContext } from '../../../searchContext/SearchContextProvider';
import SearchResultAnalyticsWrapper from '../SearchResultAnalyticsWrapper';
import { ANALYTICS_RESULT_CLASS, ANALYTICS_WRAPPER_CLASS } from '../../../util/analyticsConstants';
import localize from '../../../util/localize';
import { useFlyoutContext } from './SearchFlyout';
import { ListItem, Link, FlexBox, StyleBackgroundColor, Span } from '@vp/swan';
import { useRecentSearch } from '../../../localStorage/useRecentSearch';

interface FlyoutResultProps {
  result: any;
  resultId: string;
  landingUrl: string;
  [prop: string]: any;
}

const FlyoutResult: React.FC<React.PropsWithChildren<FlyoutResultProps>> = ({
  result,
  resultId,
  children,
  landingUrl,
  a11yLabel,
  isCustomerWork,
  isCustomerResult,
  ...otherProps
}) => {
  const { siteAnalyticsEventName, analyticsQueryString } = useFlyoutContext();
  const { handleKeyDown, preventBlur, query, locale, applicationName } = useSearchContext();
  const [id] = useState<string>(guid());
  const [background, setBackground] = useState<StyleBackgroundColor>('standard');
  const { addRecentSearch } = useRecentSearch();

  const appendAnalyticsQuery = (url: string) => url + (url.includes('?') ? '&' : '?') + analyticsQueryString;

  const sendFlyoutAnalytics = (evt) => {
    const appRoot = evt.currentTarget.closest(`.${ANALYTICS_WRAPPER_CLASS}`);
    const resultWrapper = evt.currentTarget.closest(`.${ANALYTICS_RESULT_CLASS}`);
    const results = appRoot.getElementsByClassName(ANALYTICS_RESULT_CLASS);
    const resultPosition = Array.from(results).indexOf(resultWrapper);
    if (window.tracking && siteAnalyticsEventName) {
      window.tracking.track(siteAnalyticsEventName, {
        category: 'Internal Search',
        label: query,
        eventDetail: `${window.location.pathname};${landingUrl};${results.length};${resultPosition + 1}`
      });
    }
  };

  return (
    <ListItem marginY={0} paddingY={0} {...otherProps} textAlign={'center'}>
      <SearchResultAnalyticsWrapper resultId={resultId} result={result}>
        <FlexBox alignItems="center" justifyContent="flex-start" flexWrap="nowrap" my={0}>
          <Link
            className={`search-flyout-item search-flyout-item-link`}
            skin={isCustomerWork ? 'standard' : 'unstyled'}
            id={id}
            role="option"
            href={appendAnalyticsQuery(landingUrl || result.landingUrl)}
            onKeyDown={(evt) => {
              if (evt.code === 'Enter') {
                sendFlyoutAnalytics(evt);
              }
              handleKeyDown(evt);
            }}
            onClick={(evt) => {
              addRecentSearch(query);
              sendFlyoutAnalytics(evt);
            }}
            onMouseDown={preventBlur}
            tabIndex={-1}
            aria-label={a11yLabel}
            width="full-width"
            backgroundColor={isCustomerResult ? 'strong' : background}
            onMouseEnter={() => {
              setBackground('strong');
            }}
            onMouseLeave={() => {
              setBackground('standard');
            }}
            p={0}
            m={0}
            style={{ fontSize: isCustomerWork ? '12px' : '16px' }}>
            <FlexBox alignItems="center">
              {children}
              {result.inStock === false && (
                <Span display="block" textAlign="left" textColor="subtle">
                  <Span fontSize="xsmall" textColor="standard" pr={2}>
                    &bull;
                  </Span>
                  {localize('outOfStock', locale)}
                </Span>
              )}
            </FlexBox>
          </Link>
        </FlexBox>
      </SearchResultAnalyticsWrapper>
    </ListItem>
  );
};

export default FlyoutResult;
