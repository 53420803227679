import { CustomerProjectsResult, FacetFilters, FacetSet, ResultsPromise } from '../../types/Results';
import Fuse from 'fuse.js';
import { ABTest } from '../../types/abTest';
import { Logger } from '../../types/Logger';

let projectCache: Promise<CustomerProjectsResult[]> | undefined = undefined;

const LOCALE_QUERY_PARAM = 'locale';

const isProjectInLocale = (url: string, locale: string) => {
  const mpvUrl = new URL(url);
  const mpvUrlPathnameSplitted = mpvUrl.pathname.split('/');

  let projectLocale;
  if (mpvUrl.searchParams.get(LOCALE_QUERY_PARAM)) {
    projectLocale = mpvUrl.searchParams.get(LOCALE_QUERY_PARAM);
  } else {
    projectLocale = mpvUrlPathnameSplitted[5];
  }
  return projectLocale.toLowerCase() === locale.toLowerCase();
};

const fillProjectsCache = (auth, locale: string, maxResults: number = 50, logger?: Logger): void => {
  if (!projectCache && auth && auth.getAuthorizationHeader) {
    projectCache = fetch(
      encodeURI(
        `https://workentityservice.design.vpsvc.com/api/v2/works?ownerId=${auth.getCanonicalId()}&tenants=VISTAPRINT-PROD&pageSize=${maxResults}&offset=0&from=omnisearch`
      ),
      {
        method: 'GET',
        headers: { authorization: auth.getAuthorizationHeader() }
      }
    )
      .then((response) => {
        if (response && response.ok) {
          return response.json().then((results) => {
            return results
              .map((result) => {
                try {
                  return {
                    landingUrl: result.design.editUrl,
                    workRevisionId: result.workRevisionId,
                    workEntity: result,
                    id: result.workRevisionId,
                    name: result.workName,
                    resultSource: 'Work Entity Service',
                    resultType: 'work_entity_service',
                    mpvUrl: result.merchandising.mpvUrl
                  };
                } catch (error) {
                  logger?.warn('Unexpected Work Entity format', {
                    contextData: {
                      source: 'omnisearch',
                      error: error.message
                    }
                  });
                  return {};
                }
              })
              .filter((result) => result.landingUrl && isProjectInLocale(result.mpvUrl, locale));
          });
        }
        return [];
      })
      .catch((e) => {
        logger?.error('Could not retrieve from WES', { contextData: { source: 'omnisearch', error: e.message } });
        return [];
      });
  }
};

const searchProjects = (projects: CustomerProjectsResult[] | undefined, query: string, logger?: Logger) => {
  try {
    if (!projects) {
      return { results: [], availableFacets: {}, totalCount: 0 };
    }
    const fuse = new Fuse(projects, {
      keys: ['name'],
      includeScore: true,
      minMatchCharLength: 2,
      ignoreLocation: true,
      threshold: 0.25,
      shouldSort: true
    });
    const results = fuse.search(query).map((result) => result.item);
    return { results, availableFacets: {}, totalCount: results.length };
  } catch (e) {
    logger?.error('Error searching projects', { contextData: { source: 'omnisearch', error: e.message } });
    return { results: [], availableFacets: {}, totalCount: 0 };
  }
};

export const ProjectsResultsPlugin = (locale: string, maxResults: number = 50) => {
  const findCustomerProjects: ResultsPromise = async (
    _id: string,
    query: string,
    _appliedFacetFilters?: FacetFilters,
    _sortStrategy?: string,
    _userAbTests?: ABTest[],
    logger?: Logger
  ): Promise<{ results: CustomerProjectsResult[]; availableFacets: FacetSet; totalCount: number }> => {
    return searchProjects(await projectCache, query, logger);
  };

  return {
    pluginId: 'myProjects',
    registerPlugin: (
      _pluginId: string,
      _appliedFacetFilters?: FacetFilters,
      _sortStrategy?: string,
      _userAbTests?: ABTest[],
      _paginationNumber?: number,
      auth?: any,
      logger?: Logger
    ) => fillProjectsCache(auth, locale, maxResults, logger),
    resultsPromise: findCustomerProjects,
    taxonomyId: 'CUSTOMER_WORK_RESULTS'
  };
};
