import React, { useState, useEffect } from 'react';
import { useSearchContext } from '../../searchContext/SearchContextProvider';

export interface AwaitAllResultsSectionProps {
  loadingView: React.FC;
  awaitOnce?: boolean;
}

const AwaitAllResultsSection: React.FC<React.PropsWithChildren<AwaitAllResultsSectionProps>> = ({
  children,
  loadingView,
  awaitOnce = false
}) => {
  const { allResultsReturned } = useSearchContext();
  const [awaiting, setAwaiting] = useState(true);

  useEffect(() => {
    setAwaiting((currAwaiting) => {
      if (allResultsReturned) {
        return false;
      } else if (awaitOnce && !currAwaiting) {
        return false;
      }
      return true;
    });
  }, [awaitOnce, allResultsReturned]);

  const LoadingComponent = loadingView;
  if (awaiting) {
    return <LoadingComponent />;
  } else {
    return <>{children}</>;
  }
};

export default AwaitAllResultsSection;
