import localize from '../../util/localize';

export interface ResultTaxonomy {
  id: string;
  localizedHeader?: (locale: string) => string;
}

export const ProductResultTaxonomy: ResultTaxonomy = {
  id: 'PRODUCTS',
  localizedHeader: (locale: string) => localize('Products', locale)
};

export const CategoriesResultTaxonomy: ResultTaxonomy = {
  id: 'CATEGORIES',
  localizedHeader: (locale: string) => localize('categories', locale)
};

export const ProductResultBertTaxonomy: ResultTaxonomy = {
  id: 'PRODUCTS_BERT',
  localizedHeader: (locale: string) => localize('Products', locale)
};
export const DesignResultTaxonomy: ResultTaxonomy = {
  id: 'DESIGNS',
  localizedHeader: (locale: string) => localize('Designs', locale)
};
export const HelpResultTaxonomy: ResultTaxonomy = {
  id: 'HELP_CENTER',
  localizedHeader: (locale: string) => localize('Help Center', locale)
};
export const InspirationResultTaxonomy: ResultTaxonomy = {
  id: 'INSPIRATION',
  localizedHeader: (locale: string) => localize('Inspiration', locale)
};

export const CustomerWorkResultTaxonomy: ResultTaxonomy = {
  id: 'CUSTOMER_WORK_RESULTS',
  localizedHeader: (locale: string) => localize('PersonalizedResults', locale)
};

export const DiscoveryPagesTaxonomy: ResultTaxonomy = {
  id: 'DISCOVERY_PAGES'
};
