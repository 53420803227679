// This imported file is created during build from buildLocalizations.js
import translations from '../localizations/translations.json';

const languageFallbacks = {
  de: 'de-de',
  en: 'en-ie',
  es: 'es-es',
  fr: 'fr-fr',
  it: 'it-it',
  nl: 'nl-nl'
};

export default function localize(phrase, locale) {
  try {
    let translatedPhrase = translations[locale.toLowerCase()]?.[phrase];
    if (!translatedPhrase) {
      const language = locale.toLowerCase().substring(0, 2);
      translatedPhrase = translations[languageFallbacks[language]][phrase];
    }
    return translatedPhrase;
  } catch (err) {
    return '';
  }
}
