import { FluidImage, SquareImageContainer } from '@vp/swan';
import React from 'react';

const buildSrcSet = (baseUrl, width) => {
  return [1, 2, 3].map((dpr) => `${baseUrl}&width=${dpr * width} ${dpr}x`).join(', ');
};

const getDefaultView = (
  tenantId,
  culture,
  productKey,
  productVersion,
  optionSelections,
  requestor,
  previewInstructionsUri,
  width
) => {
  const qs = `productVersion=${productVersion}&optionSelections=${encodeURIComponent(JSON.stringify(optionSelections))}&purpose=design.studio.review&requestor=${requestor}&previewInstructionsUri=${previewInstructionsUri}`;

  const baseUrl = `https://purcs.design.vpsvc.com/api/v1/${tenantId}/${culture}/product/${productKey}/defaultViewImage?${qs}`;
  return {
    src: `${baseUrl}&width=${width}`,
    srcSet: buildSrcSet(baseUrl, width)
  };
};

export const PurcsPreview = ({
  coreProductId,
  coreProductVersion,
  renderingUrl,
  optionSelections,
  requestor = 'omnisearch',
  tenantId,
  locale,
  width
}) => {
  const preview = getDefaultView(
    tenantId,
    locale,
    coreProductId,
    coreProductVersion,
    optionSelections,
    requestor,
    renderingUrl,
    width
  );
  return (
    <SquareImageContainer style={{ width: width }}>
      <FluidImage src={preview.src} alt="" srcSet={preview.srcSet} loading="lazy" />
    </SquareImageContainer>
  );
};
