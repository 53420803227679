import React, { useEffect } from 'react';
import { useSearchContext, DEFAULT_MIN_QUERY_LENGTH } from '../../../searchContext/SearchContextProvider';
import localize from '../../../util/localize';
import { SearchResultsDisplay } from '../SearchResultsDisplay';
import { createCtx } from '../../../util/createCtx';
import { ANALYTICS_WRAPPER_CLASS } from '../../../util/analyticsConstants';
import { GridContainer, useScreenClass, Box, Link, VisuallyHidden, tokens } from '@vp/swan';
import './SearchFlyout.scss';
import { displayConfigurationDecider } from './searchBarDisplayConfigurations';
import { ResultDisplayConfiguration } from '../../../types/Results';
import { useRecentSearch } from '../../../localStorage/useRecentSearch';

export const FlyoutFooter = ({ seeAllText }: { seeAllText?: string }) => {
  const { query, allResultsUrl, locale, handleKeyDown, preventBlur, applicationName } = useSearchContext();
  const { addRecentSearch } = useRecentSearch();

  const track = (evt) => {
    if (window.tracking) {
      const appRoot = evt.currentTarget.closest(`.${ANALYTICS_WRAPPER_CLASS}`) as any;
      const application = appRoot?.dataset?.searchApplicationName;
      window.tracking.track('Search Submitted', {
        query,
        application,
        location: window.location.pathname
      });
    }
  };

  return (
    <Link
      key="search-flyout-footer"
      href={allResultsUrl}
      tabIndex={-1}
      className="search-flyout-footer-link"
      skin="cta"
      onClick={(evt) => {
        addRecentSearch(query);
        track(evt);
      }}
      onKeyDown={(evt) => {
        if (evt.code === 'Enter') {
          track(evt);
        }
        handleKeyDown(evt);
      }}
      onMouseDown={preventBlur}
      mt={3}
      mb={5}
      ml={5}
      pt={2}
      pr={2}
      pl={2}
      fontSkin="body-small-bold">
      {seeAllText || localize('allResultsText', locale)}
    </Link>
  );
};

export interface SearchFlyoutProps {
  minQueryLen?: number;
  veilEnabled: boolean;
  siteAnalyticsEventName?: string;
  getSiteAnalyticsQueryString?: (query: string) => string;
  seeAllText?: string;
  applicationName?: string;
  resultDisplayConfiguration?: ResultDisplayConfiguration;
}

interface FlyoutProviderState {
  siteAnalyticsEventName?: string;
  analyticsQueryString: string;
}

export const [useFlyoutContext, FlyoutContext] = createCtx<FlyoutProviderState>();

const SearchFlyout: React.FC<SearchFlyoutProps> = ({
  veilEnabled,
  minQueryLen = DEFAULT_MIN_QUERY_LENGTH,
  siteAnalyticsEventName,
  getSiteAnalyticsQueryString = (query: string) => `swsquery=${query}&querycat=search_dropdown`,
  seeAllText,
  applicationName,
  resultDisplayConfiguration
}) => {
  const {
    results,
    query,
    isFlyoutExpanded,
    setIsFlyoutExpanded,
    isFocused,
    selectedResult,
    allResultsUrl,
    instanceId,
    locale
  } = useSearchContext();

  const screenClass = useScreenClass();
  const flyoutPaddingPixels =
    Object.keys(results).some((taxonomy) => results[taxonomy].length) && screenClass === 'xs' ? 3 : 0;

  useEffect(() => {
    setIsFlyoutExpanded(Object.keys(results).length > 0 && allResultsUrl.length > 0 && query.length >= minQueryLen);
  }, [setIsFlyoutExpanded, results, allResultsUrl, query]);

  return (
    <FlyoutContext value={{ siteAnalyticsEventName, analyticsQueryString: getSiteAnalyticsQueryString(query) }}>
      {(isFocused || selectedResult !== 0) && (
        <>
          {veilEnabled && isFlyoutExpanded && (
            <Box
              className="search-flyout-veil"
              mt={2}
              style={{
                backgroundColor: tokens.SwanSemColorBrandMidnight,
                opacity: 0.85
              }}
              z={tokens.SwanSemZIndexOverlay}
            />
          )}
          <Box
            id={`siteSearchResultsListBox-${instanceId}`}
            className={`search-flyout-wrapper`}
            paddingTop={flyoutPaddingPixels}
            width="100%">
            <VisuallyHidden
              id={`siteHeaderSearchFlyoutLabel-${instanceId}`}
              aria-live="polite"
              aria-atomic="true"
              role="status">
              {results && Object.keys(results).length > 0 && (
                <>
                  <VisuallyHidden className={`r-${query}`} aria-hidden="true">
                    {query}
                  </VisuallyHidden>
                  {localize('searchFlyoutLabel', locale)}
                </>
              )}
            </VisuallyHidden>
            <div className="search-flyout">
              <GridContainer
                id={`siteHeaderSearchFlyout`}
                className={`search-flyout-drop-down`}
                display={isFlyoutExpanded ? 'block' : 'none'}
                style={{
                  borderRadius: tokens.SwanSemBorderRadiusStrong
                }}
                marginTop={{ sm: 2, md: 2, lg: 2, xl: 2, xs: 0 }}
                bgc={'standard'}
                p={0}>
                {results && (
                  <SearchResultsDisplay
                    resultDisplayConfiguration={{
                      ...displayConfigurationDecider(applicationName),
                      ...resultDisplayConfiguration
                    }}
                  />
                )}
                {results && allResultsUrl && query.length >= minQueryLen && <FlyoutFooter seeAllText={seeAllText} />}
              </GridContainer>
            </div>
          </Box>
        </>
      )}
    </FlyoutContext>
  );
};

export default SearchFlyout;
