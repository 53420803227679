import React, { useCallback, useState, useEffect, useReducer } from 'react';
import * as abReader from '@vp/ab-reader';
import { fireImpression } from '@vp/ab-reader';
import { createCtx } from '../util/createCtx';
import {
  ADD_RESULTS,
  CLEAR_RESULTS,
  RESET_RESULTS,
  SearchContextReducer,
  SET_CURRENT_QUERY,
  SET_ALL_RESULTS_URL,
  ADD_RENDERER,
  APPLY_FACET,
  REMOVE_FACET,
  UPDATE_FACET_SELECTIONS,
  CompoundFacetAction,
  SimpleFacetAction,
  FacetSpecification,
  APPLY_SORT,
  SortAction,
  RESET_RESULTS_AND_FACETS
} from './SearchContextReducer';
import {
  FacetSet,
  FacetFilters,
  ResultDisplayConfiguration,
  ResultPlugin,
  SectionTrackingDetails
} from '../types/Results';
import { TaxonomyRenderer } from '../types/TaxonomyRenderer';
import { ENABLED_AB_TEST_FEATURES } from '../util/abTestConstants';
import type { ABTest, EnabledTestFeatures } from '../types/abTest';
import { getUrlPrefix } from '../util/secondaryLanguageUrlPrefix';
import { Logger } from '../types/Logger';
import DOMPurify from 'dompurify';

export const DEFAULT_MIN_QUERY_LENGTH = 3;

export const [useSearchContext, SearchContext] = createCtx<SearchApi>();

export interface SearchApi {
  query: string;
  setQuery: (newQuery: string) => void;
  addResults: (results: any[], taxonomyId: string) => void;
  updateResultsPaginationNumber: (taxonomyId: string, resultsPaginationNumber: number) => void;
  results: { [taxonomyId: string]: any[] };
  previousResults: { [taxonomyId: string]: any[] };
  availableFacets: (taxonomies?: string[]) => FacetSet;
  totalCount: { [taxonomyId: string]: number };
  appliedFacets: (taxonomies?: string[]) => FacetFilters;
  selectFacet: (facetName: string, facetValue: string | string[], taxonomyId?: string | string[]) => void;
  removeFacet: (facetName: string, facetValue: string | string[], taxonomyId?: string | string[]) => void;
  updateFacetSelections: (
    facetsToApply: FacetSpecification | FacetSpecification[],
    facetsToRemove: FacetSpecification | FacetSpecification[],
    taxonomyId?: string | string[]
  ) => void;
  setSortStrategy: (strategy: string, taxonomyId?: string | string[]) => void;
  resultsPageUrl: string;
  allResultsUrl: string;
  locale: string;
  isFlyoutExpanded: boolean; //TODO: should this be pushed down to input/flyout component level
  setIsFlyoutExpanded: (isExpanded: boolean) => void; //TODO: should this be pushed down to input/flyout component level
  isFocused: boolean; //TODO: should this be pushed down to input/flyout component level?
  checkFocus: () => void; //TODO: should this be pushed down to input/flyout component level?
  preventBlur: (e) => void; //TODO: should this be pushed down to input/flyout component level?
  selectedResult: number; //TODO: should this be pushed down to input/flyout component level?
  activeResultId: string | undefined; //TODO: should this be pushed down to input/flyout component level?
  handleKeyDown: (e: any) => void; //TODO: should this be pushed down to input/flyout component level?
  getSearchInput: (el: HTMLElement) => HTMLElement; //TODO: should this be pushed down to input/flyout component level?
  renderers: { [taxonomyId: string]: TaxonomyRenderer };
  registerTaxonomyRenderer: (renderer: TaxonomyRenderer, taxonomyId: string) => void;
  resultDisplayConfiguration: ResultDisplayConfiguration;
  instanceId: string;
  allResultsReturned: boolean;
  userAbTests: ABTest[];
  filteredUserAbTests: ABTest[];
  fireImpression: (ABTest) => Promise<void>;
  applicationName: string;
  searchInputHasBeenTouched: boolean;
  setSearchInputHasBeenTouched: (touchedState: boolean) => void;
  sectionTrackingDetails: { [taxonomyId: string]: SectionTrackingDetails };
  logger?: Logger;
}
export interface InternalSearchApplicationState {
  currentQuery: string;
  results: { [taxonomyId: string]: any[] };
  previousResults: { [taxonomyId: string]: any[] };
  totalCount: { [taxonomyId: string]: number };
  availableFacets: { [taxonomyId: string]: FacetSet };
  appliedFacets: { [taxonomyId: string]: FacetFilters };
  allResultsUrl: string;
  renderers: { [taxonomyId: string]: TaxonomyRenderer };
  reportedResultPluginCount: number;
  sortStrategies: { [taxonomyId: string]: string };
  sectionTrackingDetails: { [taxonomyId: string]: SectionTrackingDetails };
  hasToAppendResults: { [taxonomyId: string]: boolean };
}

interface SearchContextProviderProps {
  minCharLength?: number;
  defaultToAwaitingResults?: boolean;
  locale?: string;
  resultsPageUrl: string;
  getResultsPageUrl?: (query: string) => string;
  resultDisplayConfiguration?: ResultDisplayConfiguration;
  queryString?: string;
  resultPlugins: ResultPlugin[];
  instanceId: string;
  applicationName: string;
  enabledAbTests?: EnabledTestFeatures;
  auth: any | undefined; //make required in next major version
  waitOnTestLoad?: boolean;
  logger?: Logger;
}

const defaultInitialState: InternalSearchApplicationState = {
  currentQuery: '',
  results: {},
  previousResults: {},
  sectionTrackingDetails: {},
  allResultsUrl: '',
  renderers: {},
  reportedResultPluginCount: 0,
  availableFacets: {},
  appliedFacets: {},
  totalCount: {},
  sortStrategies: {},
  hasToAppendResults: {}
};

const defaultResultDisplayConfiguration: ResultDisplayConfiguration = {
  maxResults: Infinity,
  maxResultsPerTaxonomy: { default: Infinity },
  taxonomyOrder: [],
  resultTypeOrderingPerTaxonomy: {},
  taxonomyConfiguration: {}
};

const trackedTests: string[] = [];

export const SearchContextProvider: React.FC<React.PropsWithChildren<SearchContextProviderProps>> = ({
  children,
  minCharLength = DEFAULT_MIN_QUERY_LENGTH,
  defaultToAwaitingResults = false,
  locale = 'en-IE',
  resultsPageUrl = `${getUrlPrefix(locale)}/search`,
  getResultsPageUrl,
  resultDisplayConfiguration,
  queryString,
  resultPlugins,
  instanceId,
  applicationName,
  enabledAbTests = ENABLED_AB_TEST_FEATURES,
  auth,
  waitOnTestLoad,
  logger
}) => {
  const MAX_RESULTS_PER_TAXONOMY = 1000;
  const initialState = { ...defaultInitialState };
  if (queryString) {
    initialState.currentQuery = queryString;
  }
  const [state, dispatch] = useReducer(SearchContextReducer, initialState);
  const [hasInitialSearch, setHasInitialSearch] = useState(false);
  const [selectedResult, setSelectedResult] = useState<number>(0); //0 indicates the input field is selcted. -1 indicates the "all results" link
  const [activeResultId, setActiveResultId] = useState<string>();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFlyoutExpanded, setIsFlyoutExpanded] = useState<boolean>(false);
  const [userAbTests, setUserAbTests] = useState<ABTest[]>([]);
  const [allTestsLoaded, setAllTestsLoaded] = useState<boolean>(false);
  const [filteredUserAbTests, setFilteredUserAbTests] = useState<ABTest[]>([]);
  const [searchInputHasBeenTouched, setSearchInputHasBeenTouched] = useState<boolean>(false);

  useEffect(() => {
    //Webpack 5 is too clever and tries to protect us from the same thing this if statement does by throwing
    //errors if we directly access initialize in any way on abReader. But apparently it doesn't know that
    //abReader2 is the same thing. Webpack is strange.
    const abReader2 = abReader;
    if ((abReader2 as any).initialize) {
      (abReader2 as any).initialize();
    }
    abReader.whenAvailable(() => {
      const experiments = abReader.getAllExperiments();
      const filteredSearchTestsFromAbTests = experiments.reduce((acc, cur) => {
        if (
          (Object.keys(enabledAbTests).includes(cur.experimentKey) &&
            !enabledAbTests[cur.experimentKey].locale &&
            !enabledAbTests[cur.experimentKey].localeList) ||
          enabledAbTests[cur.experimentKey]?.locale === locale.toLowerCase() ||
          enabledAbTests[cur.experimentKey]?.localeList?.includes(locale.toLowerCase())
        ) {
          return [...acc, { Experiment: cur.experimentKey, Variation: cur.variationKey }];
        }
        return acc;
      }, []);
      dispatch({ type: RESET_RESULTS_AND_FACETS });
      setUserAbTests(
        experiments.map((experiment) => {
          return {
            Experiment: experiment.experimentKey,
            Variation: experiment.variationKey
          };
        })
      );
      setFilteredUserAbTests(filteredSearchTestsFromAbTests);
      setAllTestsLoaded(true);
    }, 2500);
  }, [abReader.whenAvailable, abReader.getAllExperiments, enabledAbTests, locale]);

  const checkFocus = () => {
    //We are focused if the search bar has the focus, or if there is a selected result
    if (!!document.activeElement?.classList.contains('search-bar-input')) {
      setSelectedResult(0);
      setIsFocused(true);
      return;
    }
    if (selectedResult > 0 || !!document.activeElement?.classList.contains('search-flyout-item')) {
      setIsFocused(true);
      return;
    }
    setIsFocused(false);
  };

  const preventBlur = (e) => {
    //We need to stop the input from blurring - otherwise clicks on flyout items don't register because the flyout closes
    e.preventDefault();
    e.stopPropagation();
  };

  const setQuery = useCallback(
    (newQuery: string) => {
      const sanitizedNewQuery = sanitizeQuery(newQuery);
      dispatch({
        type: SET_CURRENT_QUERY,
        value: sanitizedNewQuery
      });
      if (!sanitizedNewQuery.length) {
        dispatch({ type: CLEAR_RESULTS });
      } else {
        dispatch({ type: RESET_RESULTS_AND_FACETS });
      }
      if (sanitizedNewQuery.length >= minCharLength) {
        setHasInitialSearch(true);
      }
      //TODO there should probably be some abstraction layer over searches here so we can impose the character limit on plugged in search sources
      dispatch({
        type: SET_ALL_RESULTS_URL,
        value: getResultsPageUrl
          ? getResultsPageUrl(sanitizedNewQuery)
          : `${resultsPageUrl}${resultsPageUrl.includes('?') ? '&query=' : '?query='}${encodeURIComponent(
              sanitizedNewQuery
            )}`
      });
    },
    [dispatch, minCharLength, setHasInitialSearch]
  );

  const fireImpressionAndStore = useCallback(async (test) => {
    if (!trackedTests.includes(test.Experiment)) {
      trackedTests.push(test.Experiment);
      fireImpression(test.Experiment, test.Variation);
    }
  }, []);

  let isCurrentResultSet = true;
  let shouldGetResults = false;
  const getResults = (
    currentQuery,
    minCharLength,
    resultPlugins,
    userAbTests,
    filteredUserAbTests,
    currAvailableFacets,
    appliedFacets,
    sortStrategies,
    resetFacets
  ) => {
    if (currentQuery?.length >= minCharLength && (!waitOnTestLoad || allTestsLoaded)) {
      resultPlugins.forEach((resultPlugin: ResultPlugin) => {
        resultPlugin
          .resultsPromise(
            resultPlugin.pluginId,
            currentQuery,
            appliedFacets[resultPlugin.taxonomyId],
            sortStrategies[resultPlugin.taxonomyId],
            userAbTests,
            logger
          )
          .then((response) => {
            if (isCurrentResultSet) {
              dispatch({
                type: ADD_RESULTS,
                taxonomyId: resultPlugin.taxonomyId,
                results: response.results,
                sectionTrackingDetails: response.sectionTrackingDetails,
                availableFacets: resetFacets
                  ? response.availableFacets
                  : Object.keys(response.availableFacets).reduce((newFacetsToKeep, currFacet) => {
                      if (!appliedFacets[resultPlugin.taxonomyId]?.[currFacet]?.count) {
                        newFacetsToKeep[currFacet] = response.availableFacets[currFacet];
                      } else {
                        newFacetsToKeep[currFacet] = Object.keys(response.availableFacets[currFacet]).reduce(
                          (newValuesToKeep, facetValue) => {
                            const oldValueCount =
                              currAvailableFacets[resultPlugin.taxonomyId]?.[currFacet]?.[facetValue];
                            const newValueCount = response.availableFacets[currFacet][facetValue];
                            if (oldValueCount && newValueCount > oldValueCount) {
                              newValuesToKeep[facetValue] = newValueCount - oldValueCount;
                            }
                            return newValuesToKeep;
                          },
                          {}
                        );
                      }
                      return newFacetsToKeep;
                    }, {}),
                totalCount: Math.min(response.totalCount || 0, MAX_RESULTS_PER_TAXONOMY),
                shouldReportComplete: true
              });
              if (trackedTests.length < filteredUserAbTests.length) {
                filteredUserAbTests.forEach(async (test) => {
                  fireImpressionAndStore(test);
                });
              }
            }
          });
      });
    }
  };

  //This useEffect has an incomplete dependency array because there is a corresponding useEffect for the other dependencies
  useEffect(() => {
    shouldGetResults = true;
    if (shouldGetResults) {
      resultPlugins.forEach((plugin) => {
        //This resets us to page 0 of results, which is the rightish thing to do if any of the below change, BUT that's really for the calling app to decide
        //so we should implement a better way of retaining what pagination number we are on at any given time if we use this functionality in the future
        plugin.registerPlugin(
          plugin.pluginId,
          state.appliedFacets[plugin.taxonomyId],
          state.sortStrategies[plugin.taxonomyId],
          filteredUserAbTests,
          0,
          auth,
          logger
        );
      });
      getResults(
        state.currentQuery,
        minCharLength,
        resultPlugins,
        userAbTests,
        filteredUserAbTests,
        state.availableFacets,
        state.appliedFacets,
        state.sortStrategies,
        true
      );
    }
    return () => {
      dispatch({ type: RESET_RESULTS_AND_FACETS });
      isCurrentResultSet = false;
      shouldGetResults = false;
    };
  }, [state.currentQuery, minCharLength, resultPlugins, userAbTests, filteredUserAbTests, auth]); //Todo: figure out how to better handle resultPlugins to preserve facet response on taxonomies that have facet filters selected

  //This useEffect has an incomplete dependency array because there is a corresponding useEffect for the other dependencies
  useEffect(() => {
    shouldGetResults = true;
    if (shouldGetResults) {
      //This resets us to page 0 of results, which is the rightish thing to do if any of the below change, BUT that's really for the calling app to decide
      //so we should implement a better way of retaining what pagination number we are on at any given time if we use this functionality in the future
      resultPlugins.forEach((plugin) => {
        plugin.registerPlugin(
          plugin.pluginId,
          state.appliedFacets[plugin.taxonomyId],
          state.sortStrategies[plugin.taxonomyId],
          filteredUserAbTests,
          0,
          auth,
          logger
        );
      });
      getResults(
        state.currentQuery,
        minCharLength,
        resultPlugins,
        userAbTests,
        filteredUserAbTests,
        state.availableFacets,
        state.appliedFacets,
        state.sortStrategies,
        false
      );
    }

    return () => {
      dispatch({ type: RESET_RESULTS });
      isCurrentResultSet = false;
      shouldGetResults = false;
    };
  }, [state.appliedFacets, state.sortStrategies]);

  const appendResults = (taxonomyId: string) => {
    resultPlugins.forEach((plugin) => {
      if (plugin.taxonomyId === taxonomyId) {
        plugin
          .resultsPromise(
            plugin.pluginId,
            state.currentQuery,
            appliedFacets[plugin.taxonomyId],
            state.sortStrategies[plugin.taxonomyId],
            userAbTests,
            logger
          )
          .then((response) => {
            dispatch({
              type: ADD_RESULTS,
              taxonomyId: plugin.taxonomyId,
              results: response.results,
              availableFacets: response.availableFacets,
              totalCount: Math.min(response.totalCount || 0, MAX_RESULTS_PER_TAXONOMY),
              shouldReportComplete: false,
              sectionTrackingDetails: response.sectionTrackingDetails
            });
          });
      }
    });
  };

  const addResults = useCallback(
    (results, taxonomyId) => {
      hasInitialSearch &&
        dispatch({
          type: ADD_RESULTS,
          results,
          taxonomyId,
          shouldReportComplete: true
        });
    },
    [dispatch, hasInitialSearch]
  );

  const updateResultsPaginationNumber = useCallback(
    (taxonomyId, resultsPaginationNumber) => {
      const plugin = resultPlugins.find((plugin) => plugin.taxonomyId === taxonomyId);
      if (plugin) {
        plugin.registerPlugin(
          plugin.pluginId,
          state.appliedFacets[plugin.taxonomyId],
          state.sortStrategies[plugin.taxonomyId],
          filteredUserAbTests,
          resultsPaginationNumber,
          auth,
          logger
        );
        appendResults(plugin.taxonomyId);
      }
    },
    [dispatch, state.appliedFacets, state.sortStrategies, userAbTests, auth]
  );

  const registerTaxonomyRenderer = useCallback(
    (renderer: TaxonomyRenderer, taxonomyId: string) => {
      dispatch({
        type: ADD_RENDERER,
        taxonomyId,
        renderer
      });
    },
    [dispatch]
  );

  const availableFacets = useCallback(
    (taxonomies?: string[]): FacetSet => {
      if (!taxonomies?.length) {
        taxonomies = Object.keys(state.availableFacets);
      }
      const facets: FacetSet = {};
      taxonomies.forEach((taxonomyId) => {
        const taxonomyFacets = state.availableFacets[taxonomyId] || {};
        //Todo: maybe shared facet-merge function here and with the reducer addResults facet functionality?
        Object.keys(taxonomyFacets).forEach((facet) => {
          if (!facets[facet]) {
            facets[facet] = {};
          }
          Object.keys(taxonomyFacets[facet]).forEach((facetValue) => {
            if (!facets[facet][facetValue]) {
              facets[facet][facetValue] = 0;
            }
            facets[facet][facetValue] += taxonomyFacets[facet][facetValue];
          });
        });
      });
      return facets;
    },
    [state.availableFacets]
  );

  const appliedFacets = useCallback(
    (taxonomies?: string[]): FacetFilters => {
      if (!taxonomies?.length) {
        taxonomies = Object.keys(state.appliedFacets);
      }
      const appliedFacets = {};
      taxonomies.forEach((taxonomy) => {
        const appliedFacetsOfTaxonomy = state.appliedFacets[taxonomy] || {};
        Object.keys(appliedFacetsOfTaxonomy).forEach((facetKey) => {
          if (!appliedFacets[facetKey]) appliedFacets[facetKey] = appliedFacetsOfTaxonomy[facetKey];
          else appliedFacets[facetKey] = new Set([...appliedFacets[facetKey], ...appliedFacetsOfTaxonomy[facetKey]]);
        });
      });
      return appliedFacets;
    },
    [state.appliedFacets]
  );

  const selectFacet = useCallback(
    (facetKey: string, facetValue: string | string[], taxonomyId?: string | string[]) => {
      if (!taxonomyId) {
        taxonomyId = resultPlugins.map((plugin) => plugin.taxonomyId);
      }
      dispatch({
        type: APPLY_FACET,
        taxonomyId,
        facetKey,
        facetValue
      } as SimpleFacetAction);
    },
    [dispatch]
  );

  const removeFacet = useCallback(
    (facetKey: string, facetValue: string | string[], taxonomyId?: string | string[]) => {
      if (!taxonomyId) {
        taxonomyId = resultPlugins.map((plugin) => plugin.taxonomyId);
      }
      dispatch({
        type: REMOVE_FACET,
        taxonomyId,
        facetKey,
        facetValue
      } as SimpleFacetAction);
    },
    [dispatch]
  );

  const updateFacetSelections = useCallback(
    (facetsToApply: FacetSpecification, facetsToRemove: FacetSpecification, taxonomyId?: string | string[]) => {
      if (!taxonomyId) {
        taxonomyId = resultPlugins.map((plugin) => plugin.taxonomyId);
      }
      dispatch({
        type: UPDATE_FACET_SELECTIONS,
        facetsToApply,
        facetsToRemove,
        taxonomyId
      } as CompoundFacetAction);
    },
    [dispatch]
  );

  const setSortStrategy = useCallback(
    (sort: string, taxonomyId?: string | string[]) => {
      if (!taxonomyId) {
        taxonomyId = resultPlugins.map((plugin) => plugin.taxonomyId);
      }
      dispatch({
        type: APPLY_SORT,
        taxonomyId,
        sort
      } as SortAction);
    },
    [dispatch]
  );

  const getSearchInput = (element) => {
    const searchInput = element.closest('.search-application')?.querySelector('.search-bar-input');
    if (searchInput.nodeName.toLowerCase() === 'input') {
      return searchInput;
    }
    return searchInput.querySelector('input');
  };

  const getFlyoutFooter = (element) => {
    return element.closest('.search-application')?.querySelector('.search-flyout-footer-link');
  };

  const getFlyoutLinks = (element) => {
    return element.closest('.search-application')?.getElementsByClassName('search-flyout-item-link');
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const searchInput = getSearchInput(event.target);
    const flyoutFooter = getFlyoutFooter(event.target);
    const flyoutLinks = getFlyoutLinks(event.target);
    const resultsCount = flyoutLinks?.length;

    switch (event.code) {
      case 'ArrowDown':
        if (!resultsCount && !flyoutFooter?.length) {
          return;
        }
        event.preventDefault();
        if (selectedResult === -1) {
          setSelectedResult(0);
          searchInput.focus();
          setActiveResultId(undefined);
          return;
        }
        if (selectedResult < resultsCount) {
          setSelectedResult(selectedResult + 1);
          const element = (flyoutLinks as HTMLElement)[selectedResult];
          element.ariaSelected = 'true';
          element?.focus();
          setActiveResultId(element.id);
          return;
        }
        if (selectedResult === resultsCount) {
          if (flyoutFooter) {
            //If there is a flyout footer, select it
            setSelectedResult(-1);
            if (document.activeElement) {
              (document.activeElement as any).ariaSelected = 'false';
            }
            setActiveResultId(undefined);
            flyoutFooter.focus();
            return;
          } else {
            // Otherwise, wrap back to the search input
            setSelectedResult(1);
            if (document.activeElement) {
              (document.activeElement as any).ariaSelected = 'false';
            }
            setActiveResultId(undefined);
            searchInput.focus();
            return;
          }
        }
        return;
      case 'ArrowUp':
        if (!resultsCount && !flyoutFooter?.length) {
          return;
        }
        event.preventDefault();
        if (selectedResult === -1) {
          setSelectedResult(resultsCount);
          const element = (flyoutLinks as HTMLElement)[resultsCount - 1];
          element.ariaSelected = 'true';
          setActiveResultId(element.id);
          element.focus();
          return;
        }
        if (selectedResult === 0) {
          if (flyoutFooter) {
            // If there is a flyout footer, wrap down to it
            setSelectedResult(-1);
            setActiveResultId(undefined);
            flyoutFooter.focus();
            return;
          } else {
            // Otherwise, wrap to the last result
            setSelectedResult(resultsCount);
            const element = (flyoutLinks as HTMLElement)[resultsCount - 1];
            element.ariaSelected = 'true';
            setActiveResultId(element.id);
            element.focus();
            return;
          }
        }
        if (selectedResult === 1) {
          setSelectedResult(0);
          if (document.activeElement) {
            (document.activeElement as any).ariaSelected = 'false';
          }
          searchInput.focus();
          setActiveResultId(undefined);
          return;
        }
        if (selectedResult > 1) {
          setSelectedResult(selectedResult - 1);
          if (document.activeElement) {
            (document.activeElement as any).ariaSelected = 'false';
          }
          const element = (flyoutLinks as HTMLElement)[selectedResult - 2];
          element.ariaSelected = 'true';
          element?.focus();
          setActiveResultId(element.id);
          return;
        }
      case 'Enter':
        return;
      default:
        setSelectedResult(0);
        searchInput.focus();
        checkFocus();
        return;
    }
  };

  const externalSearchApi: SearchApi = {
    query: state.currentQuery,
    setQuery,
    isFocused,
    isFlyoutExpanded,
    setIsFlyoutExpanded,
    checkFocus,
    preventBlur,
    addResults,
    updateResultsPaginationNumber,
    results: state.results,
    previousResults: state.previousResults,
    availableFacets,
    totalCount: state.totalCount,
    appliedFacets,
    selectFacet,
    removeFacet,
    updateFacetSelections,
    setSortStrategy,
    locale,
    resultsPageUrl,
    allResultsUrl: state.allResultsUrl,
    selectedResult,
    activeResultId,
    handleKeyDown,
    getSearchInput,
    renderers: state.renderers,
    registerTaxonomyRenderer,
    resultDisplayConfiguration: { ...defaultResultDisplayConfiguration, ...resultDisplayConfiguration },
    instanceId,
    allResultsReturned:
      (state.currentQuery && state.currentQuery.length >= minCharLength) || defaultToAwaitingResults
        ? resultPlugins.length === state.reportedResultPluginCount
        : true,
    userAbTests,
    filteredUserAbTests,
    fireImpression: fireImpressionAndStore,
    applicationName,
    searchInputHasBeenTouched,
    setSearchInputHasBeenTouched,
    sectionTrackingDetails: state.sectionTrackingDetails,
    logger
  };

  return (
    <SearchContext value={externalSearchApi}>
      <div className="search-context-inner-wrapper">{children}</div>
    </SearchContext>
  );
};

function sanitizeQuery(query: string): string {
  const sanitizedQuery = DOMPurify.sanitize(query);
  const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g;
  return sanitizedQuery.replace(urlRegex, '');
}
