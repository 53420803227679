import React, { useState, useEffect } from 'react';
import { SearchInput, StandardForm, FormField, FormLabel } from '@vp/swan';
import { useSearchContext } from '../../searchContext/SearchContextProvider';

import './SearchInput.scss';
import { ANALYTICS_WRAPPER_CLASS } from '../../util/analyticsConstants';
import localize from '../../util/localize';
import { useRecentSearch } from '../../localStorage/useRecentSearch';

export interface SearchInputProps {
  searchLabel: string;
  searchPlaceholder: string;
  searchedTerm: string | undefined;
}

const OmniSearchInput: React.FC<SearchInputProps> = ({ searchLabel, searchPlaceholder }: SearchInputProps) => {
  const {
    locale,
    query,
    setQuery,
    setIsFlyoutExpanded,
    handleKeyDown,
    allResultsUrl,
    instanceId,
    activeResultId,
    setSearchInputHasBeenTouched,
    checkFocus,
    applicationName
  } = useSearchContext();
  const [searchEngaged, setSearchEngaged] = useState(false);
  const { addRecentSearch } = useRecentSearch();

  useEffect(() => {
    const params = document?.location.search && new URLSearchParams(document.location.search);
    const query = params ? params?.has('query') && params?.get('query') : '';
    if (query) {
      setQuery(query);
    }
  }, []);

  const handleChange = (evt) => {
    setSearchInputHasBeenTouched(true);
    if (window.tracking && !searchEngaged) {
      const appRoot = evt.currentTarget.closest(`.${ANALYTICS_WRAPPER_CLASS}`);
      const application = appRoot.dataset.searchApplicationName;
      window.tracking.track('Search Bar Engaged', { application, location: window.location.pathname });
      setSearchEngaged(true);
    }
    setQuery(evt.target.value);
  };

  const queryParams = new URL(allResultsUrl, 'http://www.does-not-matter.com').searchParams;

  return (
    <div className="search-group" role="group">
      <search>
        <StandardForm
          action={allResultsUrl}
          onSubmit={(evt) => {
            if (!query) {
              evt.preventDefault();
              return;
            }

            addRecentSearch(query);

            if (window.tracking) {
              const appRoot = evt.currentTarget.closest(`.${ANALYTICS_WRAPPER_CLASS}`) as any;
              const application = appRoot?.dataset?.searchApplicationName;
              window.tracking.track('Search Submitted', {
                query,
                application,
                location: window.location.pathname
              });
            }
          }}>
          {Array.from(queryParams.keys()).map((key: string, index) => {
            return <input type="hidden" name={key} value={queryParams.get(key)!} key={key + index} />;
          })}
          <FormField className="search-bar-form-field">
            <FormLabel visuallyHidden>{searchLabel}</FormLabel>
            <SearchInput
              className="search-bar-input"
              accessibleTextForClearButton={localize('clearLabel', locale)}
              accessibleTextForSearchButton={searchPlaceholder}
              placeholder={searchPlaceholder}
              value={query}
              aria-controls={`siteHeaderSearchFlyoutLabel-${instanceId}`}
              aria-owns={`siteSearchResultsListBox-${instanceId}`}
              aria-activedescendant={activeResultId}
              onChange={handleChange}
              onKeyDown={(evt) => {
                if (evt.code === 'Escape') {
                  setIsFlyoutExpanded(false);
                }
                handleKeyDown(evt);
              }}
              onClear={() => {
                setQuery('');
                setSearchInputHasBeenTouched(true);
              }}
              onFocus={() => {
                checkFocus();
              }}
              onBlur={() => {
                checkFocus();
              }}
            />
          </FormField>
        </StandardForm>
      </search>
    </div>
  );
};

export default OmniSearchInput;
