import React from 'react';
import { Logger } from '../types/Logger';

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: React.PropsWithChildren<{ component: string; logger?: Logger; fallback?: React.Component }>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    this.props.logger?.error(`Error rendering ${this.props.component}`, {
      source: 'omnisearch',
      contextData: { error: error.message }
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
