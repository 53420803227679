import React from 'react';
import { PurcsPreview } from '../CustomerWorkPreview';
import { Box, List, FluidImage, SquareImageContainer, Typography, Icon, FlexBox } from '@vp/swan';
import { useSearchContext } from '../../../searchContext/SearchContextProvider';
import localize from '../../../util/localize';
import { TaxonomyRenderer } from '../../../types/TaxonomyRenderer';
import FlyoutResult from './FlyoutResult';
import ResultText from './ResultText';

type ResultComponent = React.FC<React.PropsWithChildren<{ result: any }>>;

const HighlightQueryFromResultText = (resultText: string) => {
  const { query } = useSearchContext();
  const startOfMatch = resultText.toLowerCase().indexOf(query);
  const queryLength = query.length;
  return startOfMatch === -1
    ? resultText
    : resultText.slice(0, startOfMatch) +
        '<em>' +
        resultText.slice(startOfMatch, startOfMatch + queryLength) +
        '</em>' +
        resultText.slice(startOfMatch + queryLength);
};

const CustomerResult = ({ result, tenant }) => {
  const { locale } = useSearchContext();

  const purcsTenant = `${tenant}-prod`;
  const ariaTitle = `${result.name}`;

  const resultText = HighlightQueryFromResultText(result.name);

  const callToActionText = (
    <FlexBox alignItems="flex-start">
      <Typography fontWeight="bold">
        {result.resultType == 'order_history' ? localize('reorder', locale) : localize('finishEditing', locale)}
      </Typography>
      <Icon iconType="arrowRight" size="24p" pl={1} />
    </FlexBox>
  );

  return (
    <FlyoutResult resultId={result.id} result={result} landingUrl={result.landingUrl} isCustomerResult>
      {result.workEntity ? (
        <Box className="search-flyout-item-image-container" display="flex">
          <PurcsPreview
            locale={locale}
            width={60}
            tenantId={purcsTenant}
            coreProductId={
              result.workEntity.product?.key ||
              result.design?.metadata?.productKey ||
              result.workEntity.resources?.productKey
            }
            coreProductVersion={result.workEntity.product?.version}
            renderingUrl={result.workEntity.design.displayUrl}
            optionSelections={result.workEntity.merchandising.merchandisingSelections}
          />
        </Box>
      ) : (
        <SquareImageContainer style={{ width: '60px', flexShrink: 0 }}>
          <FluidImage src={result.livePreviewUrl} alt="" margin="auto" />
        </SquareImageContainer>
      )}
      <Box className="customer-work-text-container" textAlign="center">
        <Box
          className="search-flyout-item-name"
          textAlign="left"
          pl={3}
          pr={2}
          pt={1}
          ml={3}
          style={{ fontSize: '16px' }}>
          <span aria-hidden="true">
            <ResultText resultText={resultText} />
            <span className="swan-visually-hidden">{ariaTitle}</span>
          </span>
        </Box>
        <Box className="customer-work-cta" textAlign="left" pl={2} pr={3} ml={4}>
          {callToActionText}
        </Box>
      </Box>
    </FlyoutResult>
  );
};

const LocalizedCustomerWorkSection = (Result: ResultComponent) => {
  return (taxonomyHeadingLocalizer): TaxonomyRenderer => {
    return ({ results, maxResults, taxonomyConfiguration, headerId }) => {
      const { locale } = useSearchContext();
      let resultsToRender: any[] = [];
      if (taxonomyConfiguration) {
        Object.keys(taxonomyConfiguration).forEach((resultType) => {
          const limit = taxonomyConfiguration[resultType];
          let count = 0;
          results.forEach((result) => {
            if (
              result.resultType === resultType &&
              count < limit &&
              resultsToRender.length < (maxResults || Infinity)
            ) {
              resultsToRender.push(result);
              count++;
            }
          });
        });
      } else {
        resultsToRender = results.slice(0, maxResults || results.length);
      }

      return (
        <>
          <Box as="span" id={headerId}>
            {taxonomyHeadingLocalizer(locale)}
          </Box>
          <List skin="minimal">
            {resultsToRender.map((result) => {
              return React.createElement(Result, { result, key: result.id });
            })}
          </List>
          <SeeAllSection results={results} locale={locale} configuration={taxonomyConfiguration} />
        </>
      );
    };
  };
};

const GetUrlFromService = async (locale: string, urlId: string): Promise<string> => {
  const url = encodeURI(`https://url.prod.merch.vpsvc.com/v3/url/vistaprint/${locale}/${urlId}?requestor=omnisearch`);
  return fetch(url)
    .then((res) => res.json())
    .then((data) => data.url);
};

const SeeAllSection = ({ results, locale, configuration }) => {
  if (!configuration) {
    return <></>;
  }
  const [projectsLink, setProjectsLink] = React.useState<string>('');
  const [orderHistory, setOrderHistoryLink] = React.useState<string>('');

  React.useEffect(() => {
    let isCurrent = true;
    const setLinks = async () => {
      const projectsUrl = await GetUrlFromService(locale, 'ma:myProjects');
      const ordersUrl = await GetUrlFromService(locale, 'ma:orderHistory');
      if (isCurrent) {
        setProjectsLink(projectsUrl);
        setOrderHistoryLink(ordersUrl);
      }
    };
    setLinks();
    return () => {
      isCurrent = false;
    };
  }, [locale]);

  // Calculate total results by result type to compare against configuration
  const resultCounts = results.reduce((total, result) => {
    if (total[result.resultType]) {
      total[result.resultType]++;
    } else {
      total[result.resultType] = 1;
    }
    return total;
  }, {});

  // Which result types need a see all link
  const typesToSeeAll = Object.keys(resultCounts).reduce((total, resultType) => {
    if (resultCounts[resultType] > configuration[resultType]) {
      total[resultType] = true;
    } else {
      total[resultType] = false;
    }
    return total;
  }, {});

  // If there's nothing to show just return an empty element
  if (!Object.values(typesToSeeAll).some((value) => value)) {
    return <></>;
  }

  //when we move from major version v2 to v3, we should update this function to use the js token package for consistency's sake.
  const seeAllLinks: (JSX.Element | undefined)[] = Object.keys(typesToSeeAll)
    .map((resultType) => {
      if (typesToSeeAll[resultType] && resultType === 'order_history') {
        const ordersAndCount = localize('orders', locale).replace('{{{ numResults }}}', resultCounts[resultType]);
        return (
          <FlyoutResult
            landingUrl={orderHistory}
            resultId={'seeAllOrders'}
            key={'seeAllOrders'}
            result={{ landingUrl: orderHistory }}
            a11yLabel={`${localize('seeAll', locale)} ${ordersAndCount}`}
            display={'inline-block'}
            isCustomerWork={true}
            isCustomerResult={true}
            p={0}>
            {ordersAndCount}
          </FlyoutResult>
        );
      } else if (typesToSeeAll[resultType] && resultType === 'work_entity_service') {
        const projectsAndCount = localize('projects', locale).replace('{{{ numResults }}}', resultCounts[resultType]);
        return (
          <FlyoutResult
            landingUrl={projectsLink}
            resultId={'seeAllProjects'}
            key={'seeAllProjects'}
            result={{ landingUrl: projectsLink }}
            a11yLabel={`${localize('seeAll', locale)} ${projectsAndCount}`}
            display={'inline-block'}
            isCustomerWork={true}
            isCustomerResult={true}>
            {projectsAndCount}
          </FlyoutResult>
        );
      }
    })
    .filter((e) => !!e);

  // Inject a pipe seperator between all see all section links
  if (seeAllLinks.length > 1) {
    for (var i = 1; i < seeAllLinks.length; i += 2) {
      seeAllLinks.splice(
        i,
        0,
        <Box as="span" display="inline-block" key={`separator-${i}`}>
          {' | '}
        </Box>
      );
    }
  }

  return (
    <List
      skin="minimal"
      className="customer-work-see-all-links"
      pt={5}
      pl={2}
      display="block"
      style={{ fontSize: '12px' }}>
      {localize('seeAll', locale)} {seeAllLinks}
    </List>
  );
};

export default LocalizedCustomerWorkSection(CustomerResult);
