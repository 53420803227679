import React, { useEffect, useState } from 'react';
import { BaseResultType, ResultDisplayConfiguration } from '../../types/Results';
import { useSearchContext } from '../../searchContext/SearchContextProvider';
import SearchResultSectionAnalyticsWrapper from './SearchResultSectionAnalyticsWrapper';
import sortResultsByType from '../../util/sortResults';
import { CustomerWorkResultTaxonomy } from '../taxonomy/Taxonomies';
import { Column, Row } from '@vp/swan';

const TAXONOMY_RESULT_LIMIT_FALLBACK = 1;

const validateDisplayConfiguration = (displayConfig: ResultDisplayConfiguration) => {
  if (displayConfig.maxResults < 0) {
    throw `Invalid Display Configuration.  MaxResults set to [${displayConfig.maxResults}].  Needs to be >= 0`;
  }
  Object.values(displayConfig.maxResultsPerTaxonomy).forEach((taxonomyMax) => {
    if (taxonomyMax < 0) {
      throw `Invalid Display Configuration.  TaxonomyMaxResult set to negative number`;
    }
  });
  if (displayConfig.taxonomyConfiguration) {
    Object.values(displayConfig.taxonomyConfiguration).forEach((taxonomyResultTypeConfiguration) => {
      taxonomyResultTypeConfiguration.forEach((resultTypeConfiguration) => {
        if (resultTypeConfiguration.maxResultsByResultType && resultTypeConfiguration.maxResultsByResultType < 0) {
          throw `Invalid Display Configuration.  ResultType: [${resultTypeConfiguration.resultTypeId}] maxResults set to negative number`;
        }
      });
    });
  }
};

const generateTaxonomyConfiguration = (results, taxonomyId: string, displayConfig: ResultDisplayConfiguration) => {
  if (!results) {
    return {};
  }

  const taxonomyResultLimit =
    displayConfig.maxResultsPerTaxonomy[taxonomyId] ||
    displayConfig.maxResultsPerTaxonomy['default'] ||
    TAXONOMY_RESULT_LIMIT_FALLBACK;

  // Initialize the taxonomyDisplayConfig with the current set of results for each type in a taxonomy
  const resultsPerResultType: { [key: string]: number } = results.reduce((accum, current) => {
    if (accum[current.resultType]) {
      accum[current.resultType] = ++accum[current.resultType];
    } else {
      accum[current.resultType] = 1;
    }
    return accum;
  }, {});

  const taxonomyDisplayConfiguration: { [key: string]: number } = {};

  // Update the taxonomyDisplayConfig
  // Use the specified taxonomyConfiguration if provided or set the config based on order of results
  if (displayConfig.taxonomyConfiguration && displayConfig.taxonomyConfiguration[taxonomyId]) {
    let resultsRemaining = taxonomyResultLimit;
    const taxonomyTypeConfig = displayConfig.taxonomyConfiguration[taxonomyId];
    Object.keys(resultsPerResultType).forEach((resultType) => {
      const configuredLimit = taxonomyTypeConfig.find(
        (resultConfig) => resultConfig.resultTypeId === resultType
      )?.maxResultsByResultType;
      if (configuredLimit !== undefined) {
        taxonomyDisplayConfiguration[resultType] = Math.min(configuredLimit, resultsRemaining);
      } else {
        taxonomyDisplayConfiguration[resultType] = Math.min(resultsPerResultType[resultType], resultsRemaining);
      }
      resultsRemaining -= taxonomyDisplayConfiguration[resultType];
    });

    // Will override the specified type max results to the taxonomyResultLimit
    if (resultsRemaining > 0) {
      Object.keys(taxonomyDisplayConfiguration).forEach((resultType) => {
        taxonomyDisplayConfiguration[resultType] = Math.min(
          taxonomyDisplayConfiguration[resultType] + resultsRemaining,
          resultsPerResultType[resultType]
        );
      });
    }
  } else {
    const taxonomyTypeIds = Object.keys(resultsPerResultType);
    let resultsRemaining = taxonomyResultLimit;
    taxonomyTypeIds.forEach((type) => {
      taxonomyDisplayConfiguration[type] = Math.min(resultsPerResultType[type], resultsRemaining);
      resultsRemaining -= taxonomyDisplayConfiguration[type];
    });
  }

  return taxonomyDisplayConfiguration;
};

export interface SearchResultsDisplayProps {
  resultDisplayConfiguration: ResultDisplayConfiguration;
}

export const SearchResultsDisplay = ({ resultDisplayConfiguration }: SearchResultsDisplayProps) => {
  const { results, renderers, fireImpression, allResultsReturned, previousResults } = useSearchContext();

  const [currentResults, setCurrentResults] = useState(previousResults);

  useEffect(() => {
    if (allResultsReturned) {
      setCurrentResults(results);
    }
  }, [allResultsReturned, results]);

  validateDisplayConfiguration(resultDisplayConfiguration);

  // Sort Taxonomies by specified taxonomyOrder.  Any non-fallback taxonomy with results not specified, will get added as they appear after
  const taxonomyOrder = resultDisplayConfiguration.taxonomyOrder;
  Object.keys(currentResults).forEach((taxonomy) => {
    const fallbackForCurrentTaxonomy = resultDisplayConfiguration.taxonomyFallback?.[taxonomy];
    const primaryTaxonomy = Object.keys(resultDisplayConfiguration.taxonomyFallback || {}).find(
      (key) =>
        resultDisplayConfiguration.taxonomyFallback?.[key] === taxonomy ||
        (resultDisplayConfiguration.taxonomyFallback?.[key] as any).fallbackTaxonomy === taxonomy
    );
    const isFallbackTaxonomy = !!primaryTaxonomy;

    //case 1: processing primary, simple fallback taxonomy
    if (!!fallbackForCurrentTaxonomy && typeof fallbackForCurrentTaxonomy === 'string') {
      //Replace if current (primary) has no results and found (fallback) does
      if (currentResults[taxonomy].length === 0 && currentResults[fallbackForCurrentTaxonomy]?.length) {
        currentResults[taxonomy] = currentResults[fallbackForCurrentTaxonomy];
      }
    }
    //case 2: processing fallback, must find primary, simple fallback
    else if (isFallbackTaxonomy && typeof resultDisplayConfiguration.taxonomyFallback?.[primaryTaxonomy] === 'string') {
      //Replace if found (primary) has no results but current (fallback) taxonomy does
      if (currentResults[primaryTaxonomy]?.length === 0 && currentResults[taxonomy]?.length) {
        currentResults[primaryTaxonomy] = currentResults[taxonomy];
      }
    }
    //case 3: processing primary, complex result replacement
    else if (!!fallbackForCurrentTaxonomy && typeof fallbackForCurrentTaxonomy === 'object') {
      const filterFunction = (result: BaseResultType) =>
        fallbackForCurrentTaxonomy.resultTypes.includes(result.resultType || '');
      //Fill in results of specified types from found (fallback) if none are present in current (primary)
      if (!currentResults[taxonomy]?.filter(filterFunction).length) {
        currentResults[taxonomy] = (currentResults[taxonomy] || []).concat(
          currentResults[fallbackForCurrentTaxonomy.fallbackTaxonomy]?.filter(filterFunction) || []
        );
      }
    }
    //case 4: processing fallback, must find primary, complex result replacement
    else if (isFallbackTaxonomy && typeof resultDisplayConfiguration.taxonomyFallback?.[primaryTaxonomy] === 'object') {
      const fallbackConfig: any = Object.values(resultDisplayConfiguration.taxonomyFallback).find(
        (fallbackConfig) => (fallbackConfig as any).fallbackTaxonomy === taxonomy
      );
      const filterFunction = (result: BaseResultType) => fallbackConfig.resultTypes.includes(result.resultType || '');
      //Fill in results of specified types from current (fallback) if none are present in found (primary)
      if (!currentResults[primaryTaxonomy]?.filter(filterFunction).length) {
        currentResults[primaryTaxonomy] = (currentResults[primaryTaxonomy] || []).concat(
          currentResults[taxonomy].filter(filterFunction) || []
        );
      }
    }
    if (!taxonomyOrder.includes(taxonomy) && !isFallbackTaxonomy) {
      taxonomyOrder.push(taxonomy);
    } else if (isFallbackTaxonomy && !taxonomyOrder.includes(primaryTaxonomy)) {
      taxonomyOrder.push(primaryTaxonomy);
    }
  });

  let renderedResults = 0;
  let availableSlots = resultDisplayConfiguration.maxResults;
  return (
    <>
      {taxonomyOrder.map((taxonomyId, index) => {
        if (
          resultDisplayConfiguration.includeTaxonomies &&
          !resultDisplayConfiguration.includeTaxonomies.includes(taxonomyId)
        ) {
          return null;
        }
        //Sort results within a taxonomy by type if specified, otherwise leave results alone
        const unfilteredResults = currentResults[taxonomyId]?.length
          ? resultDisplayConfiguration.resultTypeOrderingPerTaxonomy[taxonomyId]?.length
            ? sortResultsByType(
                currentResults[taxonomyId],
                resultDisplayConfiguration.resultTypeOrderingPerTaxonomy[taxonomyId]
              )
            : currentResults[taxonomyId]
          : undefined;

        const taxonomyResults = unfilteredResults?.filter(
          (result) => !result.algoliaResult || !result.algoliaResult.shouldHide
        );
        let currType = unfilteredResults?.[0].resultType || '',
          indexInType = 0;
        const abTestHiddenResults = unfilteredResults
          ?.map((result, index) => {
            if (!result.algoliaResult?.hideInTests?.length) {
              return undefined;
            }
            if (result.resultType !== currType) {
              indexInType = 0;
              currType = result.resultType;
            }
            const hiddenResult = {
              tests: result.algoliaResult.hideInTests,
              index,
              indexInType,
              type: result.resultType
            };
            indexInType++;
            return hiddenResult;
          })
          .filter((hiddenResult) => hiddenResult);
        if (
          !taxonomyResults?.length &&
          renderedResults < resultDisplayConfiguration.maxResults &&
          resultDisplayConfiguration.maxResultsPerTaxonomy[taxonomyId] > 0
        ) {
          abTestHiddenResults
            ?.filter(
              (result) =>
                resultDisplayConfiguration.taxonomyConfiguration?.[taxonomyId].find(
                  (config) => config.resultTypeId === result?.type
                ) ?? 1 > 0
            )
            .flatMap((result) => result!.tests)
            .forEach((test) => fireImpression(test));
        }

        const taxonomyConfiguration = generateTaxonomyConfiguration(
          taxonomyResults,
          taxonomyId,
          resultDisplayConfiguration
        );

        //Limit rendered results to maxResults
        const numResultsToRender = Object.values(taxonomyConfiguration).reduce(
          (total, maxResultsPerType) => (total += maxResultsPerType),
          0
        );

        if (numResultsToRender + renderedResults > resultDisplayConfiguration.maxResults) {
          Object.keys(taxonomyConfiguration).forEach((taxonomyType) => {
            const remainingResultsToRender = resultDisplayConfiguration.maxResults - renderedResults;
            taxonomyConfiguration[taxonomyType] = Math.min(
              taxonomyConfiguration[taxonomyType],
              remainingResultsToRender
            );
            renderedResults += taxonomyConfiguration[taxonomyType];
          });
        } else {
          renderedResults += numResultsToRender;
        }

        const willRenderResults =
          Object.values(taxonomyConfiguration).reduce((total, maxResultsPerType) => (total += maxResultsPerType), 0) >
          0;

        if (
          !taxonomyResults?.length &&
          (resultDisplayConfiguration.maxResultsPerTaxonomy[taxonomyId] ??
            resultDisplayConfiguration.maxResultsPerTaxonomy['default'] > 0) &&
          renderedResults < resultDisplayConfiguration.maxResults
        ) {
          abTestHiddenResults
            ?.reduce((acc, result) => {
              if (
                resultDisplayConfiguration.taxonomyConfiguration?.[taxonomyId]?.find(
                  (config) => config.resultTypeId === result!.type
                )?.maxResultsByResultType ??
                1 > 0
              ) {
                acc.push(result);
              }
              return acc;
            }, [] as any)
            .flatMap((result) => result!.tests)
            .forEach((test) => fireImpression(test));
        }

        if (taxonomyResults?.length && renderers[taxonomyId] && willRenderResults) {
          const maxResults = Math.min(
            resultDisplayConfiguration.maxResultsPerTaxonomy[taxonomyId] ||
              resultDisplayConfiguration.maxResultsPerTaxonomy['default'],
            currentResults[taxonomyId].length,
            availableSlots
          );

          availableSlots -= maxResults;
          let hiddenSoFar = 0;
          currType = abTestHiddenResults?.[0]?.type || '';
          abTestHiddenResults
            ?.reduce((acc, result) => {
              if (currType !== result!.type) {
                currType = result!.type;
                hiddenSoFar = 0;
              }
              if (result!.index - hiddenSoFar < taxonomyConfiguration[result!.type]) {
                acc.push(result);
                hiddenSoFar++;
              }
              return acc;
            }, [] as any)
            .flatMap((result) => result!.tests)
            .forEach((test) => fireImpression(test));

          return (
            <Row
              key={taxonomyId}
              className={
                taxonomyId == CustomerWorkResultTaxonomy.id
                  ? 'search-flyout-customer-work-result-section'
                  : 'search-results-section'
              }
              backgroundColor={taxonomyId == CustomerWorkResultTaxonomy.id ? 'strong' : undefined}
              aria-labelledby={`${taxonomyId.replace(' ', '')}-label`}
              paddingX={6}
              pt={index === 1 ? 2 : taxonomyId == CustomerWorkResultTaxonomy.id ? 5 : 0}
              pb={taxonomyId == CustomerWorkResultTaxonomy.id ? 5 : undefined}
              marginBottom={taxonomyId == CustomerWorkResultTaxonomy.id ? 4 : undefined}>
              <Column span={12} paddingX={0}>
                <SearchResultSectionAnalyticsWrapper taxonomyId={taxonomyId} sectionName={taxonomyId}>
                  {React.createElement(renderers[taxonomyId], {
                    results: taxonomyResults,
                    maxResults,
                    key: taxonomyId,
                    headerId: `${taxonomyId.replace(' ', '')}-label`,
                    taxonomyConfiguration,
                    fillToMaxResults: resultDisplayConfiguration.fillToMaxResults
                  })}
                </SearchResultSectionAnalyticsWrapper>
              </Column>
            </Row>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};
