import React from 'react';
import { FlyoutResultProps } from './FlyoutResultHelpers';
import getRendererWithHeadingForResultType from './RendererWithLocalizedHeadingFactory';
import FlyoutResult from './FlyoutResult';
import ResultText from './ResultText';
import { SquareImageContainer, Typography, FluidImage, tokens } from '@vp/swan';

const Result = ({ result }: FlyoutResultProps) => {
  const label = `${result?._highlightResult?.title?.value} - ${result?._highlightResult?.description?.value}`;
  const ariaTitle = `${result?.title}
                    ${result?.description || ''}`;

  return (
    <FlyoutResult resultId={result.objectID} result={result} landingUrl={result.landingUrl}>
      <SquareImageContainer
        bgc="strong"
        style={{ width: tokens.SwanSemSpace9, flexShrink: 0, borderRadius: tokens.SwanSemBorderRadiusStandard }}>
        <FluidImage
          className="search-flyout-item-image"
          src={result.imageUrl}
          alt=""
          margin="auto"
          style={{ borderRadius: '4px' }}
        />
      </SquareImageContainer>
      <Typography className="search-flyout-item-name" textAlign="left" pl={3} pr={2} ml={3}>
        <span aria-hidden="true">
          <ResultText resultText={label} />
        </span>
        <span className="swan-visually-hidden">{ariaTitle}</span>
        <span>{/* this is needed to stop Voiceover (mac) reading the wrong number of elements in the list*/}</span>
      </Typography>
    </FlyoutResult>
  );
};

export default getRendererWithHeadingForResultType(Result);
