import React, { useEffect, useState } from 'react';
import SearchInput from './base/components/SearchInput';
import SearchFlyout from './base/components/flyout/SearchFlyout';
import SearchApplication, { SearchApplicationProps } from './SearchApplication';
import localize from './util/localize';
import TaxonomyRenderers from './base/renderers/TaxonomyRenderers';
import { ResultDisplayConfiguration, ResultPlugin } from './types/Results';
import { EnabledTestFeatures } from './types/abTest';
import { FlyoutDefaultResultPlugins } from './base/results/FlyoutResultPlugins';
import { ScreenClassProvider } from '@vp/swan';
import ErrorBoundary from './util/ErrorBoundary';
import * as abReader from '@vp/ab-reader';
import { SEARCH_BAR_FLYOUT_APPLICATION_NAME } from './util/constants';
import { QUERY_CATEGORIZATION_EXPERIMENT, QUERY_CATEGORIZATION_EXPERIMENT_VARIATION } from './util/abTestConstants';
import { Experiment } from './types/Experiment';

export interface SearchBarProps extends SearchApplicationProps {
  searchLabel?: string;
  searchPlaceholder?: string;
  seeAllText?: string;
  instanceId?: string;
  applicationName?: string;
  resultDisplayConfiguration?: ResultDisplayConfiguration;
  veilEnabled?: boolean;
  flyoutEnabled?: boolean;
  flyoutSiteAnalyticsEventName?: string;
  getFlyoutSiteAnalyticsQueryString?: (query: string) => string;
  enabledAbTests?: EnabledTestFeatures;
  searchedTerm?: string;
}

const SearchBar: React.FC<React.PropsWithChildren<SearchBarProps>> = ({
  children,
  searchLabel,
  searchPlaceholder,
  seeAllText,
  instanceId,
  minCharLength,
  locale,
  applicationName = SEARCH_BAR_FLYOUT_APPLICATION_NAME,
  resultDisplayConfiguration,
  veilEnabled = true,
  flyoutEnabled = true,
  queryString,
  resultPlugins,
  resultsPageUrl,
  getResultsPageUrl,
  flyoutSiteAnalyticsEventName,
  getFlyoutSiteAnalyticsQueryString,
  enabledAbTests,
  searchedTerm,
  auth,
  logger
}) => {
  const [experiments, setExperiments] = useState<Experiment[] | undefined>(undefined);
  const [plugins, setResultPlugins] = useState<ResultPlugin[]>(resultPlugins ?? []);

  useEffect(() => {
    if (abReader.initialize) {
      abReader.initialize();
    }
    abReader.whenAvailable(() => {
      const experiments = abReader.getAllExperiments();
      const experimentsSet: Experiment[] = experiments.map((experiment) => {
        return {
          Experiment: experiment.experimentKey,
          Variation: experiment.variationKey
        };
      });

      setExperiments(experimentsSet);
    }, 2500);
  }, [abReader.whenAvailable, abReader.getAllExperiments]);

  useEffect(() => {
    if (!resultPlugins && experiments) {
      const hitsPerPage =
        Math.max(...Object.values(resultDisplayConfiguration?.maxResultsPerTaxonomy || [0])) ||
        resultDisplayConfiguration?.maxResults;
      const queryCategorizationExperiment = experiments.find(
        (experiment) => experiment.Experiment === QUERY_CATEGORIZATION_EXPERIMENT
      );
      setResultPlugins(FlyoutDefaultResultPlugins(locale, hitsPerPage, queryCategorizationExperiment));
    }
  }, [experiments]);

  return (
    <ScreenClassProvider>
      <SearchApplication
        minCharLength={minCharLength}
        locale={locale}
        queryString={queryString}
        applicationName={applicationName}
        resultPlugins={plugins}
        instanceId={instanceId}
        resultsPageUrl={resultsPageUrl}
        getResultsPageUrl={getResultsPageUrl}
        resultDisplayConfiguration={resultDisplayConfiguration}
        enabledAbTests={enabledAbTests}
        auth={auth}
        logger={logger}>
        <SearchInput
          searchLabel={searchLabel || localize('searchLabel', locale)}
          searchPlaceholder={searchPlaceholder || localize('searchPlaceholder', locale)}
          searchedTerm={searchedTerm}
        />

        {flyoutEnabled && (
          <ErrorBoundary logger={logger} component={`${applicationName}-flyout`}>
            <SearchFlyout
              veilEnabled={veilEnabled}
              minQueryLen={minCharLength}
              siteAnalyticsEventName={flyoutSiteAnalyticsEventName}
              seeAllText={seeAllText}
              getSiteAnalyticsQueryString={getFlyoutSiteAnalyticsQueryString}
              applicationName={applicationName}
              resultDisplayConfiguration={resultDisplayConfiguration}
            />
            <TaxonomyRenderers />
          </ErrorBoundary>
        )}
        {children}
      </SearchApplication>
    </ScreenClassProvider>
  );
};

export default SearchBar;
