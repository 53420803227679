import React from 'react';
import SearchResultAnalyticsWrapper from '../../base/components/SearchResultAnalyticsWrapper';

const Result = ({ resultId, result, children, ...otherProps }) => {
  return (
    <SearchResultAnalyticsWrapper resultId={resultId} result={result} {...otherProps}>
      {children}
    </SearchResultAnalyticsWrapper>
  );
};

export default Result;
