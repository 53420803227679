import React from 'react';
import { AlgoliaResult, BaseResultType } from 'src/types/Results';
import { useSearchContext } from '../../searchContext/SearchContextProvider';
import { ANALYTICS_RESULT_CLASS, ANALYTICS_SECTION_CLASS } from '../../util/analyticsConstants';

const SectionAnalyticsWrapper: React.FC<
  React.PropsWithChildren<{ taxonomyId: string; sectionName: string; analyticsListSection?: string }>
> = ({ children, taxonomyId, sectionName, analyticsListSection }) => {
  const { query, results, allResultsReturned, applicationName, sectionTrackingDetails } = useSearchContext();
  const sectionRef = React.useRef(null);

  const trackingEvent = {
    query: query,
    sectionName,
    location: window.location.pathname,
    applicationName
  };

  const getRenderedResultIds = (section: HTMLElement) => {
    return Array.from(section.getElementsByClassName(ANALYTICS_RESULT_CLASS)).map((element) => {
      return element.getAttribute('data-result-id');
    });
  };

  const fireSearchResultSectionWhenReady = (section: HTMLElement, resultsFound) => {
    let attemptCount = 1;
    const resultRenderInterval = setInterval(() => {
      if (attemptCount >= 20) {
        //stop trying after 1 second
        clearInterval(resultRenderInterval);
        return;
      }
      attemptCount++;
      const renderedResultIds = getRenderedResultIds(section);

      if (!renderedResultIds.length) return;

      trackingEvent['shownResultsInSection'] = resultsFound.filter((result) => renderedResultIds.includes(result.id));
      window.tracking.track('Search Result Section', trackingEvent);

      clearInterval(resultRenderInterval);
    }, 50);
  };

  React.useEffect(() => {
    if (window.tracking && allResultsReturned) {
      let resultsWithTypos = 0;
      const resultsFound = results[taxonomyId]
        ?.map((result: BaseResultType) => {
          if ((result as AlgoliaResult)?.algoliaResult && (result as AlgoliaResult).algoliaResult.shouldHide) {
            return undefined;
          }
          resultsWithTypos += (result as AlgoliaResult)?.algoliaResult?.rankingInfo?.nbTypos > 0 ? 1 : 0;
          return {
            resultSource: result?.resultSource,
            id: result?.id
          };
        })
        .filter((result) => result);
      if (!resultsFound) {
        return;
      }
      trackingEvent['resultsInSection'] = resultsFound.slice(0, 200); //Too many results can exceed Segment's event size limit
      trackingEvent['rulesApplied'] = sectionTrackingDetails[taxonomyId]?.rules;
      trackingEvent['resultsWithTyposCount'] = resultsWithTypos;
      trackingEvent['totalResultCount'] = resultsFound.length;
      const section = sectionRef.current as unknown as HTMLElement;
      let renderedResultIds = getRenderedResultIds(section);

      if (!renderedResultIds.length) {
        fireSearchResultSectionWhenReady(section, resultsFound);
      } else {
        trackingEvent['shownResultsInSection'] = resultsFound.filter((result) =>
          renderedResultIds.includes(result!.id)
        );
        window.tracking.track('Search Result Section', trackingEvent);
      }
    }
  }, [allResultsReturned]);

  return (
    <div
      className={ANALYTICS_SECTION_CLASS}
      data-search-analytics-section-name={sectionName}
      data-search-analytics-list-name={analyticsListSection || sectionName}
      ref={sectionRef}>
      {children}
    </div>
  );
};

export default SectionAnalyticsWrapper;
