import aa from 'search-insights';
import React, { useEffect } from 'react';
import { ALGOLIA_APP_ID, ALGOLIA_CLIENT } from '../../util/algolia';
import { useSearchContext } from '../../searchContext/SearchContextProvider';
import {
  ANALYTICS_RESULT_CLASS,
  ANALYTICS_SECTION_CLASS,
  ANALYTICS_WRAPPER_CLASS
} from '../../util/analyticsConstants';
import { getQueryParam } from '../../util/queryString';

declare global {
  interface Window {
    tracking: any;
  }
}

interface ResultAnalyticsProps {
  resultId: string;
  result: any;
  [prop: string]: any;
}
let algoliaUserToken;

try {
  aa('init', {
    appId: ALGOLIA_APP_ID,
    apiKey: ALGOLIA_CLIENT,
    useCookie: true //sets an automatic user token (browser session level) so that events will be sent
  });

  aa('getUserToken', null, (err, token) => {
    if (err) {
      console.log('error getting Algolia user token');
      return;
    }
    algoliaUserToken = token;
  });

  aa(
    'onUserTokenChange',
    (token) => {
      algoliaUserToken = token;
    },
    { immediate: true }
  );
} catch {}

const SearchResultAnalyticsWrapper: React.FC<React.PropsWithChildren<ResultAnalyticsProps>> = ({
  children,
  resultId,
  result,
  className = '',
  ...otherProps
}) => {
  const { query, userAbTests, fireImpression } = useSearchContext();

  useEffect(() => {
    if (result?.showInAbTests) {
      result.showInAbTests.forEach((test) => {
        if (test != 'none') {
          const testToFire = userAbTests.find((userTest) => test.startsWith(userTest.Experiment));
          if (testToFire) {
            fireImpression(testToFire);
          }
        }
      });
    }
    if (result?.hideInAbTests) {
      result.hideInAbTests.forEach((test) => {
        if (test != 'none') {
          const testToFire = userAbTests.find((userTest) => test.startsWith(userTest.Experiment));
          if (testToFire) {
            fireImpression(testToFire);
          }
        }
      });
    }
  }, [result, fireImpression, userAbTests]);

  const getTrackingData = (evt) => {
    const link = evt.target.closest('a');

    const appRoot = evt.currentTarget.closest(`.${ANALYTICS_WRAPPER_CLASS}`);
    const application = appRoot.dataset.searchApplicationName;

    //Array.from rather than a spread operator is required for optimal behavior such as allowing operations such as indexOf to succeed
    const sections = Array.from(appRoot.getElementsByClassName(ANALYTICS_SECTION_CLASS)).filter((section: Element) => {
      const containedResults = Array.from(section.getElementsByClassName(ANALYTICS_RESULT_CLASS));
      //Only count sections that directly contain results
      return (
        containedResults.length > 0 &&
        containedResults.some((containedResult) => containedResult.closest(`.${ANALYTICS_SECTION_CLASS}`) === section)
      );
    });

    const currentSection = evt.currentTarget.closest(`.${ANALYTICS_SECTION_CLASS}`);
    const sectionPosition = Array.from(sections).indexOf(currentSection);
    const sectionName = currentSection.dataset.searchAnalyticsSectionName;
    const listSectionId = currentSection.dataset.searchAnalyticsListName;

    const results = appRoot.getElementsByClassName(ANALYTICS_RESULT_CLASS);
    const resultPosition = Array.from(results).indexOf(evt.currentTarget);

    const sectionResults = currentSection.getElementsByClassName(ANALYTICS_RESULT_CLASS);
    const sectionResultPosition = Array.from(sectionResults).indexOf(evt.currentTarget);

    const pageName = (document.querySelector('meta[name="pageName"]') as any)?.content;

    return {
      category: 'Internal Search',
      query,
      sectionName,
      sectionPosition,
      resultPosition,
      resultDestination: link?.attributes.href?.nodeValue,
      resultIndex: result?.resultSource,
      resultId,
      application,
      location: window.location.pathname,
      label: query,
      searchTerm: query,
      eventDetail: `${pageName || window.location.pathname};${link?.attributes.href?.nodeValue};${results.length};${resultPosition + 1};${sectionPosition + 1};${sectionResultPosition + 1}`,
      product_id: result?.pricingProductId ? result.objectID : '',
      name: result?.pricingProductId ? result.title : '',
      core_product_id: result?.pricingProductId || '',
      product_version: result?.pricingProductId ? `${result.productVersion}` : '',
      position: sectionResultPosition + 1,
      list_section_id: listSectionId,
      algoliaUserToken,
      algoliaQueryId: result?.algoliaResult?.queryId, //expected to be undefined for non-algolia results
      algoliaIndexPosition: result?.algoliaResult?.searchPosition, //expected to be undefined for non-algolia results
      routingEligible: !!getQueryParam('reroutedSearch'),
      hasTypo: result?.algoliaResult?.rankingInfo?.nbTypos > 0
    };
  };

  const onClick = (evt) => {
    const trackingEvent = getTrackingData(evt);
    if (window.tracking) {
      window.tracking.track('Search Result Clicked', trackingEvent);
    }
    if (result?.algoliaResult) {
      try {
        aa('clickedObjectIDsAfterSearch', {
          eventName: `Search Result Clicked - ${trackingEvent.application}`,
          index: result.algoliaResult.indexName,
          queryID: result.algoliaResult.queryId,
          objectIDs: [result.objectID],
          positions: [result.algoliaResult.searchPosition]
        });
      } catch (ex) {
        console.log('Failed to send search insights event');
      }
    }
  };

  let alreadyFiredRightClick = false;
  //Can't detect new tab from right click menu, but we can detect that the menu was opened
  const onRightClick = (evt) => {
    if (window.tracking && !alreadyFiredRightClick) {
      const trackingEvent = getTrackingData(evt);
      window.tracking.track('Search Result Right-Clicked', trackingEvent);
      alreadyFiredRightClick = true;
    }
  };
  return (
    <div
      {...otherProps}
      className={`${ANALYTICS_RESULT_CLASS} ${className}`}
      data-result-id={result?.id || resultId}
      onClick={onClick}
      onContextMenu={onRightClick}
      onAuxClick={onRightClick}>
      {children}
    </div>
  );
};

export default SearchResultAnalyticsWrapper;
